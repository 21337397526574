export class UserModel {
  firstName?: string;
  lastName?: string;
  company?: string;
  email?: string;
  id?: number;
  language?: number;
  groupId?: number;
  mobile?: number;
  paymentTypeDebit?: number;
  phone?: string;
  userName?: string;
}
