import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DownloadFileResponseInterface } from '../../modules/cases/models/downloadFile/download-file-response-interface';
import { AttachmentResponse } from '../../modules/billing/models/attachment-response';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class FileTransferService {
  constructor(
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  /**
   * Nimmt ein Blob entgegen und startet den Download Process im Browser des Users
   *
   * @param Blob   blob     zu downloadende Datei
   * @param string fileName Dateiname
   */
  downloadBlob(blob: Blob, fileName: string): void {
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    const objectUrl = window.URL.createObjectURL(blob);
    anchor.href = objectUrl;
    if (blob.type === 'zip') {
      anchor.download = fileName + '.' + blob.type;
    } else if (blob.type === 'pdf') {
      anchor.download = fileName + '.' + blob.type;
    } else {
      anchor.download = fileName;
    }
    anchor.click();
    window.URL.revokeObjectURL(objectUrl);
  }

  /**
   * Konvertiert einen durch das Backend erstellten Base64 String in eine Blobdatei
   *
   * @param string b64String     Der Base64 String
   * @param string fileExtension Der zu erstellende Dateityp
   *
   * @return die erstellte Datei
   */

  convertB64StringToBlob(b64String: string, fileExtension: string): Blob {
    fileExtension = fileExtension || '';
    // TODO: sliceSize erhöhen und mit größerer Datei austesten (mit Auftrag 79155 als testcase und original datei herunterladen)
    const sliceSize = 512;
    b64String = b64String.replace(/^[^,]+,/, '');
    b64String = b64String.replace(/\s/g, '');
    const byteCharacters = window.atob(b64String);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: fileExtension });
    return blob;
  }

  /**
   * Konvertiert ein im Frontend zu verfügung gestellte Blobdatei und erstelltet einen Base64 String daraus
   *
   * @param string b64String     Der Base64 String
   * @param string fileExtension Der zu erstellende Dateityp
   *
   * @return die erstellte Datei
   */
  convertBlobToB64String(_blobFile: Blob): string {
    return '';
  }

  /**
   * Nimmt einen Request entgegen, fuhrt diesen aus und signalisiert dem Nutzer das etwas am laden ist.
   * @param responseObservable request
   */
  async handeFileDownload(
    responseObservable: Observable<DownloadFileResponseInterface>
  ) {
    this.setIsLoading();
    responseObservable.subscribe(
      (response) => {
        const blob = this.convertB64StringToBlob(
          response.data.base64String,
          response.data.attachmentType
        );
        this.downloadBlob(blob, response.data.fileName);
      },
      () => {
        this.removeIsLoading();
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('GENERAL.ERROR'),
          detail: this.translateService.instant('GENERAL.ERROR'),
        });
      },
      () => {
        this.removeIsLoading();
      }
    );
  }

  /**
   * Nimmt einen Request entgegen, fuhrt diesen aus und signalisiert dem Nutzer das etwas am laden ist.
   * @param responseObservable request
   */
  async handleAttachmentDownload(
    responseObservable: Observable<AttachmentResponse>
  ) {
    this.setIsLoading();
    responseObservable.subscribe(
      (response) => {
        const blob = this.convertB64StringToBlob(
          response.data.base64,
          response.data.file_ext
        );
        this.downloadBlob(blob, response.data.fileName);
      },
      () => {
        this.removeIsLoading();
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('GENERAL.ERROR'),
          detail: this.translateService.instant('GENERAL.ERROR'),
        });
      },
      () => {
        this.removeIsLoading();
      }
    );
  }

  setIsLoading() {
    const mybody = document.getElementById('body');
    mybody?.classList.add('waiting'); // set cursor to hourglass
  }

  removeIsLoading() {
    const mybody = document.getElementById('body');
    mybody?.classList.remove('waiting');
  }
}
