import { PermissionsModel } from './permissionsModel';

export class User {
  // first_name = '';
  // last_name = '';
  // company?: string;
  // email?: string;
  // id?: number;
  // user_name = '';
  // password = '';
  // group_id: number;
  // active: boolean;
  // language: number;
  // phone: string;
  // mobile: string;
  // global_active: boolean;

  id: number = 0;
  groupId: number = 0;
  companyId: number = 0;
  company: string = '';
  active: boolean = false;
  language: number = 0;
  loginPot: Date = new Date();
  loginPot2: Date = new Date();
  userName: string = '';
  lastName: string = '';
  firstName: string = '';
  email: string = '';
  phone: string = '';
  mobile: string = '';
  globalActive: string = '';
  createPot: Date = new Date();
  changePot: Date = new Date();
  sendStatusEmails: boolean = false;
  paymentTypeDebit: number = 0;
  forwardedWithCouponCase: number = 0;
  department: string = '';
  businessUnitId: number = 0;
  businessUnitName: string = '';
  isDeleted: boolean = false;
  deletedAt: Date = new Date();
  password: string = '';
  confirmPassword: string = '';
  city: string = '';
  isValidPassword: boolean = true;

  userProperties: PermissionsModel = {} as PermissionsModel;

  can(permission: string): boolean {
    return permission === 'view_Home' || permission === '';
  }
}
