<div class="sidebarLayout">
  <div
    class="navigationContainer"
    *ngFor="let categorie of menuItems; let c = index"
  >
    <!--      <label class="navigationCategory font_small_info fontColorLightgrey" *ngIf="c !== 0">-->
    <!--        <h4>{{categorie.category}}</h4> <br />-->
    <!--      </label>-->
    <div
      class="navigationItemRow"
      *ngFor="let item of categorie.items; let i = index"
    >
      <div
        class=""
        style="width: 100%"
        [routerLink]="item.link"
        (click)="changeAtiveItem(c, i)"
        [tooltipDisabled]="isExpanded"
        pTooltip="{{ item.name | translate }}"
      >
        <div
          class="navigationItemRow fontColorWhite"
          [ngClass]="item.choosen ? 'newOfferContainer' : ''"
        >
          <img
            *ngIf="item.choosen"
            class="navigationItem"
            src="../../../assets/img/globalMenu/ActiveState.svg"
          />
          <img
            *ngIf="!item.choosen"
            class="navigationItem"
            src="../../../assets/img/globalMenu/DeactiveState.svg"
          />
          <i class="navigationItem pi {{ item.icon }}"></i>
          <p *ngIf="isExpanded" class="navigationItem font_normal">
            {{ item.name | translate | uppercase }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div
    class="expandNavigationContainer"
    (click)="changeBarState()"
    pTooltip="{{ 'GLOBAL_MENU.EXPAND_MENU' | translate }}"
  >
    <div>
      <div class="" style="width: 100%">
        <div class="navigationItemRow fontColorWhite">
          <i
            *ngIf="isExpanded"
            class="navigationItem pi pi-arrow-circle-left"
          ></i>
          <i
            *ngIf="!isExpanded"
            class="navigationItem pi pi-arrow-circle-right"
          ></i>
        </div>
      </div>
    </div>
  </div>
</div>
