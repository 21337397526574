import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';
import { Category } from './models/category';
import { CustomerMenuSupplier } from './services/customer-menu-supplier';
import { LogService } from '../logging/log.service';
import { Router } from '@angular/router';
import { CurrentUserService } from '../services/current-user.service';
import { OrganizationManagementService } from '../services/organization-management.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() active: boolean = true;
  @Output() barCollapsedEmitter = new EventEmitter<boolean>();

  isExpanded: boolean = false;
  menuItems: Category[] = [];
  urlSubscription: any;

  constructor(
    public translateService: TranslateService,
    public authService: AuthService,
    public logger: LogService,
    private router: Router,
    private currentUserService: CurrentUserService,
    private organizationManagementService: OrganizationManagementService
  ) {}

  ngOnInit(): void {
    this.isExpanded = this.getMenuStateFromLocale();
    this.barCollapsedEmitter.emit(this.isExpanded);
    const menu = new CustomerMenuSupplier();
    this.menuItems = menu.getCustomerMenu();
    this.organizationManagementService
      .getCompany(this.currentUserService.getUserComapnyId())
      .subscribe((e) => {
        if (
          e.data[0].invoiceType === 1 &&
          !this.currentUserService.getUsersPermissions().CAN_DOWNLOAD_BILLS
        ) {
          this.menuItems = menu.getCustomerMenu(false);
        }
      });

    this.menuItems = menu.getCustomerMenu();
    this.urlSubscription = this.router.events.subscribe((_e) => {
      this.setCurrentMenuPathToActive();
    });
  }

  ngOnDestroy(): void {
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
    }
  }

  /**
   * set all category items choosen in MenueItems as folse ,
   * then find the new right item where the user chossen and set it as true ;
   * @param c menu Item index number
   * @param i menue category Item index number
   */
  changeAtiveItem(c: number, i: number) {
    this.menuItems[c].items[i].choosen = true;
  }

  /**
   * get the current Page from route and select the right active meneu Item
   */
  setCurrentMenuPathToActive() {
    this.clearActiveItems();
    const route = window.location.pathname.split(/(.+)[^\d]/)[1];
    for (let c = 0; c < this.menuItems.length; c++) {
      for (let i = 0; i < this.menuItems[c].items.length; i++) {
        if (
          route?.includes(this.menuItems[c].items[i].link.split(/(.+)[^\d]/)[1])
        ) {
          this.changeAtiveItem(c, i);
        }
      }
    }
  }

  /**
   * sets the choosen flag for all menu items to false; No highlighting;
   */
  clearActiveItems() {
    this.menuItems.forEach((category) =>
      category.items.forEach((item) => (item.choosen = false))
    );
  }

  /**
   * Defines if the menu shoulb expanded or not and emits that to the parrent component.
   */
  changeBarState() {
    this.isExpanded = !this.isExpanded;
    this.barCollapsedEmitter.emit(this.isExpanded);
    this.saveBarState(this.isExpanded);
  }

  /**
   * Saves if the menubar is expanded or not to the locale storage
   * @param exPansionState
   */
  saveBarState(exPansionState: boolean) {
    localStorage.setItem('globalmenuExpansionState', exPansionState.toString());
  }

  /**
   * Reads from the local storage if the menubar is expanded or not and returns it as a bool
   */
  getMenuStateFromLocale(): boolean {
    const menuState = localStorage.getItem('globalmenuExpansionState');
    if (menuState) {
      return JSON.parse(menuState);
    } else {
      return true;
    }
  }
}
