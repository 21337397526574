/* eslint-disable  @typescript-eslint/no-non-null-assertion */

import { LogPublisher } from '../log-publisher';
import { Observable, of } from 'rxjs';
import { LogEntry } from '../log-entry';

export class LogConsolePublisher extends LogPublisher {
  /**
   * clear the console
   * @returns Observable<boolean>
   */
  clear(): Observable<boolean> {
    // console.clear();
    return of(true);
  }

  /**
   * write the record.buildLogString() in the console using the console log
   * @param record
   * @returns Observable<boolean>
   */
  log(record: LogEntry): Observable<boolean> {
    // console.log(record.buildLogString());
    return of(!!record);
  }
}
