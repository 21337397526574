import { HttpClient } from '@angular/common/http';
import { LogPublisher } from './log-publisher';
import { LogConsolePublisher } from './publisher/log-console-publisher';
import { LogWebApiPublisher } from './publisher/log-web-api-publisher';
import { LogLocalStoragePublisher } from './publisher/log-local-storage-publisher';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LogPublisherService {
  publishers: LogPublisher[] = [];

  constructor(private http: HttpClient) {
    this.buildPublishers();
  }

  /**
   *check witch log in the environment the user want to use and use it here.
   */
  buildPublishers(): void {
    if (environment.log.logToConsole) {
      this.publishers.push(new LogConsolePublisher());
    }
    if (environment.log.logToWebAPI) {
      this.publishers.push(new LogWebApiPublisher(this.http));
    }
    if (environment.log.logToLocalStorage) {
      this.publishers.push(new LogLocalStoragePublisher());
    }
  }
}
