import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  Observable,
  retry,
  map,
  catchError,
  scheduled,
  queueScheduler,
} from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { ApiException } from './api-exception';
import { LogService } from '../logging/log.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  ignoredUrls: string[] = [];
  constructor(
    private notificationService: NotificationService,
    private logService: LogService,
    private translate: TranslateService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.headers.get('ignoreError') === 'true' ||
      this.ignoredUrls.includes(request.url)
    ) {
      return next.handle(request);
    } else {
      return next.handle(request).pipe(
        retry(0), // nochmal versuchen
        map((event: HttpEvent<any>) => {
          return event;
        }),

        catchError((error: any) => {
          if (error.status === 404) {
            this.logService.error(
              '404 ApiException',
              this.constructor.name,
              error
            );
            const ex = new ApiException(
              'Die angeforderte Resource konnte nicht gefunden werden.'
            );
            ex.additionalInfo = request.url;
            ex.title = 'NotFound';
            throw ex;
          } else if (error.status === 403) {
            // this.router.navigate(['/forbidden']); // TODO: CB Forbidden Seite notwendig?

            const ex = new ApiException('403_FORBIDDEN');
            this.logService.error(
              '403_FORBIDDEN ApiException',
              this.constructor.name,
              error
            );
            ex.additionalInfo = error.error;
            ex.displayError = true;
            throw ex;
          } else if (error.status === 401) {
            // this.userService.logout();
            this.router.navigate(['/login']);

            const ex = new ApiException('401_NOT_LOGGED_IN');
            this.logService.error(
              '401_NOT_LOGGED_IN ApiException',
              this.constructor.name,
              error
            );
            ex.displayError = false;
            throw ex;
          } else if (error.status === 0) {
            this.logService.error(
              'Unknown Error',
              this.constructor.name,
              error
            );
            //TODO: Prüfen warum bei reload dieser Fehler geschmissen wird
            // this.notificationService.showMessage(
            // 'UNKNOWN_0_ERROR_TITLE',
            // 'UNKNOWN_0_ERROR_TEXT',
            // 'error',
            // true
            // );
          } else {
            const ex = new ApiException(this.translate.instant('UNKNOWN'));
            this.logService.error(
              'UNKNOWN ApiException',
              this.constructor.name,
              error
            );
            ex.additionalInfo = request.url;
            ex.stacktrace = error.error;
            throw ex;
          }

          return scheduled(
            [
              new HttpResponse({
                body: error.error,
              }),
            ],
            queueScheduler
          );
        })
      );
    }
  }
}
