import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LogEntry } from '../log-entry';
import { LogPublisher } from '../log-publisher';

export class LogWebApiPublisher extends LogPublisher {
  constructor(private http: HttpClient) {
    super();
  }

  clear(): Observable<boolean> {
    return of(true);
  }

  /**
   * send a post request to backend to save the loge message , source and level in log server
   * @param record
   * @returns Observable<boolean>
   */
  log(record: LogEntry): Observable<boolean> {
    return this.http.post<boolean>(`${environment.baseUrl}log`, {
      message: record.message,
      source: record.source,
      level: record.level,
    });
  }

  // clear(): Observable<boolean> {
  //   return of(true);
  // }

  // log(record: LogEntry): Observable<boolean> {
  //   return of(true)
  // }
}
