import { Injectable } from '@angular/core';
import { of, Subject, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TokenResponseInterface } from '../auth/token-response-interface';

@Injectable({
  providedIn: 'root',
})
export class JwtTimeOutService {
  private expireTokenTimer = new Subscription();
  private refreshTokenTimer = new Subscription();
  private tokenExpiredSubject = new Subject();
  private refreshTokenHeadsUpSubject = new Subject();
  private token: string = '';

  constructor(private jwtHelper: JwtHelperService, private http: HttpClient) {}

  startExpirationTracking() {
    let storedToken = null;
    this.token
      ? (storedToken = this.token)
      : (storedToken = localStorage.getItem('token'));
    if (storedToken) {
      this.token = storedToken;
      this.setExpirationCounter();
    }
  }

  setExpirationCounter() {
    const timeout = this.getTimeOutTimeStamp();
    if (timeout > 0) {
      this.startLogoutCounter(timeout);
      this.startRefreshTokenHeadsUpCounter(timeout);
    }
  }

  getTimeOutTimeStamp() {
    const token = this.jwtHelper.decodeToken(this.token);
    const expDate = new Date(token['exp'] * 1000);
    return expDate.valueOf() - new Date().valueOf();
  }

  startRefreshTokenHeadsUpCounter(timeout: number) {
    const refreshTimer =
      timeout - environment.jwtTimeoutHeadsUpInSeconds * 1000;
    this.refreshTokenTimer.unsubscribe();
    this.refreshTokenTimer = of(null)
      .pipe(delay(refreshTimer))
      .subscribe(() => {
        this.refreshTokenHeadsUpSubject.next(timeout);
      });
  }

  startLogoutCounter(timeout: number) {
    this.expireTokenTimer.unsubscribe();
    this.expireTokenTimer = of(null)
      .pipe(delay(timeout))
      .subscribe(() => {
        this.tokenExpiredSubject.next(true);
      });
  }

  getTokenExpiredSubject(): Subject<any> {
    return this.tokenExpiredSubject;
  }

  getRefreshTokenHeadsUpSubject(): Subject<any> {
    return this.refreshTokenHeadsUpSubject;
  }

  extendToken() {
    const response = this.http.get<TokenResponseInterface>(
      `${environment.baseUrl}refreshedToken`
    );
    response.subscribe((resp: TokenResponseInterface) => {
      this.token = resp.data.access_token;
      this.startExpirationTracking();
    });
    return response;
  }
}
