import { Injectable } from '@angular/core';
import { User } from '../model/user.model';
import { PermissionsModel } from '../model/permissionsModel';
import { GenericResponseInterface } from '../model/generic-response.interface';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { OciPropertiesModel } from '../model/oci-properties.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

/**
 * Helper Class to handle the currently Stored User
 */
export class CurrentUserService {
  userBehaviorSubject: BehaviorSubject<User> = new BehaviorSubject<User>(
    this.getUser()
  );
  constructor(private http: HttpClient) {}

  /**
   * Get the current User, stored in local storage
   */
  getUser(): User {
    let user = new User();
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      user = Object.assign(new User(), JSON.parse(storedUser));
    }
    return user;
  }

  getUserBehaviorSubject() {
    return this.userBehaviorSubject;
  }

  updateCurrentUserSubject(user: User) {
    this.userBehaviorSubject.next(user);
  }

  /**
   * return the user Permission from the stored user in the local storage
   * @returns
   */
  getUsersPermissions(): PermissionsModel {
    return this.getUser().userProperties;
  }

  /**
   * get the user id from the stored user in the localstorage.
   * @returns user Id number
   */
  getCurrentUserId(): number {
    return this.getUser().id;
  }

  /**
   * Updates the current User, stored in local storage
   */
  upateCurrentUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  /**
   * update the user default language .
   * @param langcode languageCode as a number.
   */
  updateCurrentUserLanguage(langcode: number) {
    const user = this.getUser();
    user.language = langcode;
    this.upateCurrentUser(user);
  }

  /**
   * Get Company Id of the current User
   */
  getUserComapnyId(): number {
    const user = this.getUser();
    return user.companyId;
  }

  /**
   * send a put request to backend to update the user.
   * @param updatesUser from type Usermodel
   * @returns
   */
  updateUser(updatesUser: User) {
    return this.http.put<GenericResponseInterface>(
      `${environment.baseUrl}users/` + updatesUser.id,
      {
        active: updatesUser.active,
        businessUnitId: updatesUser.businessUnitId,
        changePot: updatesUser.changePot,
        companyId: updatesUser.companyId,
        createPot: updatesUser.createPot,
        deletedAt: updatesUser.deletedAt,
        department: updatesUser.department,
        email: updatesUser.email,
        firstName: updatesUser.firstName,
        forwardedWithCouponCase: updatesUser.forwardedWithCouponCase,
        globalActive: updatesUser.globalActive,
        groupId: updatesUser.groupId,
        id: updatesUser.id,
        isDeleted: updatesUser.isDeleted,
        language: updatesUser.language,
        lastName: updatesUser.lastName,
        loginPot: updatesUser.loginPot,
        loginPot2: updatesUser.loginPot2,
        mobile: updatesUser.mobile,
        paymentTypeDebit: updatesUser.paymentTypeDebit,
        phone: updatesUser.phone,
        sendStatusEmails: updatesUser.sendStatusEmails,
        userName: updatesUser.userName,
        userProperties: {
          CAN_ACCESS_COMPANY_CASES:
            updatesUser.userProperties.CAN_ACCESS_COMPANY_CASES,
          CAN_DOWNLOAD_BILLS: updatesUser.userProperties.CAN_DOWNLOAD_BILLS,
          CAN_EDIT_COMPANY_DATA:
            updatesUser.userProperties.CAN_EDIT_COMPANY_DATA,
          CAN_MANAGE_COMPANY_USERS:
            updatesUser.userProperties.CAN_MANAGE_COMPANY_USERS,
        },
      }
    );
  }

  /**
   * Stores/Updated the OCI properties
   */
  storeOCIData(OCIData: OciPropertiesModel) {
    localStorage.setItem('OCIData', JSON.stringify(OCIData));
  }

  /**
   * Get the currently stored OCIData
   */
  loadOCIData(): OciPropertiesModel {
    let OCIData = {} as OciPropertiesModel;
    const storedOCIData = localStorage.getItem('OCIData');
    if (storedOCIData) {
      OCIData = Object.assign(new User(), JSON.parse(storedOCIData));
    }
    return OCIData;
  }

  /**
   * Returns if the OCI-procedure is active
   */
  isOCIProcedureActive(): boolean {
    const OCIProperties = this.loadOCIData();
    return (
      this.getUsersPermissions().CAN_USE_OCI &&
      OCIProperties.hookUrl !== undefined &&
      OCIProperties.target !== undefined
    );
  }
  /**
   * Returns the return target url, if loggedin by link and set returntarget
   */
  getReturnTarget(): string {
    return this.loadOCIData().returntarget || '';
  }

  /**
   * Returns true if User is part of Roehm-group
   */
  isRoehmUser(): boolean {
    const userCompany = this.getUser().company;
    return (
      ['Röhm GmbH', 'Mönch Kunststoff-Technik GmbH', 'CyPlus GmbH'].indexOf(
        userCompany
      ) !== -1
    );
  }

  endUserSubscription() {
    this.userBehaviorSubject.complete();
  }
}
