/* eslint-disable  @typescript-eslint/no-non-null-assertion */

import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { AuthService } from 'src/app/core/auth/auth.service';
import { User } from '../model/user.model';
import { LanguageTranslateService } from '../services/language-translate.service';
import { CurrentUserService } from '../services/current-user.service';
import { OciPropertiesModel } from '../model/oci-properties.model';
import { JwtTimeOutService } from '../services/jwt-time-out.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public user: User | null = null;
  public showLogin: boolean = true;

  constructor(
    public router: Router,
    private config: PrimeNGConfig,
    public authService: AuthService,
    public translateService: TranslateService,
    public langTranslateService: LanguageTranslateService,
    private route: ActivatedRoute,
    private currentUserservice: CurrentUserService,
    private jwtTimeOutService: JwtTimeOutService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.loginWithLink();
    this.translateService.addLangs(['de', 'en']);
    this.translateService.setDefaultLang('de');
    this.user = this.authService.wakeUp();
    if (this.user !== null) {
      if (
        !this.user.isValidPassword &&
        this.user.isValidPassword !== undefined
      ) {
        this.authService.showResetPassword();
      }
      if (this.user?.language === 49) {
        this.translateService.use('de');
      } else {
        this.translateService.use('en');
      }
    }
    this.handleUpcomingTimeout();
    this.langTranslateService.init();
    this.translateService
      .get('primeng')
      .subscribe((res) => this.config.setTranslation(res));
  }

  /**
   * if the user try to login with link the link will look like this
   * http://localhost:4200/app/index.php?ACTION=DO_LOGIN&username=ltk1.42@web.de&password=Metalevel1&hook_url=https://punchoutcommerce.com/tools/oci-roundtrip-return&OCI_VERSION=4.0&http_content_charset=utf-8&returntarget=_parent&target=_tophttp://localhost:4200/app/index.php
   * so this Method extract the login information from the link UserName and Password and send it to AuthService.login ;
   * during this Process if there is info in the link then we don't show the login Mask in HTML by making the showLogin = false ;
   */
  private loginWithLink() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const OCIParams: OciPropertiesModel = {} as OciPropertiesModel;
        let loginWithOci: boolean = false;
        const params = new URLSearchParams(event.url);
        const newParams = new URLSearchParams();
        // @ts-ignore
        // eslint-disable-next-line prefer-const
        for (let [name, value] of params) {
          if (name.startsWith('/?')) {
            name = name.split('/?')[1];
          }
          newParams.append(name.toLowerCase(), value);
        }
        const pathUserName = newParams.get('username');
        const pathPassword = newParams.get('password');
        const returnTarget = newParams.get('returntarget') ?? '';
        const jwtToken = newParams.get('jwttoken');
        const userId = newParams.get('userid');
        OCIParams.hookUrl = newParams.get('hook_url') ?? '';
        OCIParams.ociVersion = newParams.get('oci_version') ?? '';
        OCIParams.httpContentCharset =
          newParams.get('http_content_charset') ?? '';
        OCIParams.returntarget = newParams.get('returntarget') ?? '';
        OCIParams.target = newParams.get('target') ?? '';

        if (OCIParams.hookUrl && OCIParams.target) {
          loginWithOci = true;
          this.currentUserservice.storeOCIData(OCIParams);
        }
        if (OCIParams.returntarget) {
          this.currentUserservice.storeOCIData(OCIParams);
        }
        if (pathUserName && pathPassword && jwtToken === null) {
          this.showLogin = false;
          this.authService.login(pathUserName, pathPassword, returnTarget, loginWithOci);
        }
        if (jwtToken && userId) {
          AuthService.storeToken(jwtToken);
          this.authService.controllerLoginAsCustomer(jwtToken, userId);
        }
      }
      this.handleUpcomingTimeout();
    });
  }

  /**
   * Receives the information from the child component whether the menubar should be expanded or not
   * and changes the style accordingly
   *
   * @param isExpanded
   */
  setBarState(isExpanded: boolean) {
    const contentArea = document.getElementById('contentArea') as HTMLElement;

    if (isExpanded) {
      contentArea.style.gridTemplateColumns = '15rem auto';
    } else {
      contentArea.style.gridTemplateColumns = '4.5rem auto';
    }
  }

  /**
   * This method opens if a timeout is triggered an menu to allow the user to refresh his access token.
   * @private
   */
  private handleUpcomingTimeout() {
    this.jwtTimeOutService.getRefreshTokenHeadsUpSubject().subscribe(() => {
      this.showExtendTokenDialogue();
    });
  }

  /**
   * Closes the dialog which enables the user to refresh the access token and requests a new access token
   */
  extendToken() {
    this.authService.extendToken();
    this.messageService.clear('extendToken');
  }

  /**
   * Closes the dialog which enables the user to refresh the access token and unsubscribes the subscriptions important for that
   */
  dontExtendToken() {
    this.messageService.clear('extendToken');
  }

  /**
   * Shows the dialog which enables the user to refresh the access token
   * */
  showExtendTokenDialogue() {
    this.messageService.clear('extendToken');
    this.messageService.add({
      key: 'extendToken',
      closable: false,
      sticky: true,
      severity: 'info',
      summary: this.translateService.instant('TIMEOUT_DIALOGUE.TITLE'),
      detail: this.translateService.instant('TIMEOUT_DIALOGUE.DETAILS'),
    });
  }

  onConfirm(): void {
    this.messageService.clear('errorDlg');
  }
}
