import { OrganizationManagementService } from '../../../../core/services/organization-management.service';
import { TranslationService } from '../../../../core/services/translation.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { WrapFormService } from '../../../../core/wrap-form/wrap-form.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private router: Router,
    public ref: DynamicDialogRef,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private translator: TranslationService,
    private wrapFormService: WrapFormService,
    private oMService: OrganizationManagementService
  ) {
    this.form = this.formBuilder.group({
      oldPassword: new UntypedFormControl('', Validators.required),
      newPassword: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$'),
      ]),
      newPasswordConfirmation: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$'),
      ]),
    });
  }

  ngOnInit(): void {
    this.wrapFormService.invokeMyMethod.subscribe(() => {
      this.closeWin();
    });
    // this.translateService.onLangChange
    //   .subscribe((event: LangChangeEvent) => {
    //     this.getAllTranslations();
    //   });
  }

  /**
   * when th euser submit the password changes then send this changes to backend and
   * if success navigate to home router and close the popup dialog ;
   */
  onSubmit(): void {
    this.form.markAsDirty();
    this.oMService
      .updatePassword(
        this.form.value.oldPassword,
        this.form.value.newPassword,
        this.form.value.newPasswordConfirmation
      )
      .subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: this.translate('GENERAL.RESPONSES.UPDATED_HEADER'),
            detail: this.translate('GENERAL.RESPONSES.UPDATED'),
          });
          this.form.markAsPristine();
          this.router.navigate(['home']);
          this.closeWin();
        },
        () => {
          this.messageService.add({
            severity: 'error',
            summary: this.translate('GENERAL.RESPONSES.UPDATE_FAIL_HEADER'),
            detail: this.translate('GENERAL.RESPONSES.UPDATE_FAIL'),
          });
        }
      );
  }

  /**
   * tranlsation methode ;
   * @param param string ;
   * @returns string ;
   */
  translate(param: string): string {
    return this.translator.getTranslation(param);
  }

  /**
   * close ths dialog popup;
   */
  closeWin() {
    this.ref.close(this.form);
  }
}
