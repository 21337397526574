import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let token: string = this.authService.token;
    this.authService.tokenSubscription.subscribe((e: string) => {
      token = e;
    });
    if (token) {
      return next.handle(
        request.clone({
          headers: request.headers.append('Authorization', 'Bearer ' + token),
        })
      );
    }
    return next.handle(request);
  }
}
