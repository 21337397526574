import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileTransferService } from '../../services/file-transfer.service';
import { UrlService } from '../../services/url.service';

@Injectable({
  providedIn: 'root',
})
export class AgbService {
  constructor(
    private http: HttpClient,
    private urlService: UrlService,
    private fileTransferService: FileTransferService
  ) {}

  /**
   * Download der AGBs
   */
  download() {
    this.fileTransferService.handeFileDownload(
      this.http.get<any>(
        this.urlService.getBaseUrl() + 'terms',
        this.urlService.getDefaultHttpOptions()
      )
    );
  }
}
