<p-toast position="top-right"></p-toast>

<p-toast
  position="bottom-center"
  key="extendToken"
  (onClose)="dontExtendToken()"
  [baseZIndex]="5000"
>
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column" style="flex: 1">
      <div class="text-center">
        <i class="pi pi-clock" style="font-size: 3rem"></i>
        <h4>{{ message.summary }}</h4>
        <p>{{ message.detail }}</p>
      </div>
      <div class="grid p-fluid">
        <div class="col-6">
          <button
            type="button"
            icon="pi pi-sign-in"
            pButton
            (click)="extendToken()"
            label="{{ 'TIMEOUT_DIALOGUE.CONFIRM' | translate }}"
            class="button-success"
          ></button>
        </div>
        <div class="p-col-6">
          <button
            type="button"
            icon="pi pi-sign-out"
            pButton
            (click)="dontExtendToken()"
            label="{{ 'TIMEOUT_DIALOGUE.DECLINE' | translate }}"
            class="button-secondary"
          ></button>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>

<ng-container
  *ngIf="
    (authService.user === undefined && showLogin) ||
    (authService.user === null && showLogin)
  "
>
  <app-login></app-login>
</ng-container>
<ng-container
  *ngIf="authService.user !== undefined && authService.user != null"
>
  <div class="container">
    <app-header-main class="header"></app-header-main>
    <div id="contentArea" class="contentArea">
      <app-menu
        class="menu-bar"
        (barCollapsedEmitter)="setBarState($event)"
      ></app-menu>
      <!--<div class="layout-mask"></div>-->
      <div class="content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-container>

<p-toast
  styleClass="leginda-big-toast"
  position="center"
  key="errorDlg"
  (onClose)="onConfirm()"
  [baseZIndex]="11000"
>
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column max-h-screen overflow-auto" style="flex: 1">
      <div class="overflow-auto">
        <em class="pi pi-exclamation-triangle" style="font-size: 3rem"></em>
        <div style="user-select: text" class="text-xl">
          {{ message.summary }}
        </div>
        <div style="user-select: text; white-space: pre-wrap">
          {{ message.detail }}
        </div>
      </div>
      <div class="col-offset-3 col-6">
        <button
          type="button"
          class="w-full"
          pButton
          (click)="onConfirm()"
          label="Ok"
        ></button>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-confirmDialog icon="pi pi-exclamation-triangle"></p-confirmDialog>
