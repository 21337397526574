import { UtilityService } from 'src/app/core/services/utility.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LogService } from '../logging/log.service';
import { GenericResponseInterface } from '../model/generic-response.interface';
import { InfoMessageService } from '../services/info-message.service';
import { TokenResponseInterface } from './token-response-interface';
import { User } from '../model/user.model';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CurrentUserService } from '../services/current-user.service';
import { UserResponseInterface } from '../model/user-response-interface';
import { JwtTimeOutService } from '../services/jwt-time-out.service';
import { OrganizationManagementService } from '../services/organization-management.service';
import { Company } from '../model/company.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public token: string = '';
  public message: string = '';
  public formIsLocked = false;
  public user: User | null = null;
  private pass: string = '';
  public tokenSubscription = new Subject<string>();
  private company: Company = {} as Company;

  constructor(
    private router: Router,
    private http: HttpClient,
    public ref: DynamicDialogRef,
    private logginservice: LogService,
    public dialogService: DialogService,
    private uiService: InfoMessageService,
    public translateService: TranslateService,
    private utilityService: UtilityService,
    private messageService: MessageService,
    private currentUserService: CurrentUserService,
    private jwtTimeOutService: JwtTimeOutService,
    private organizationManagementService: OrganizationManagementService,
    private confirmationService: ConfirmationService
  ) {}

  /**
   *
   * @returns pass string
   */
  public getUserPass(): string {
    return this.pass;
  }

  /**
   * send an EndPoint API to Backend then get back an auth Token and save it in Lokal
   *
   * @param username string
   * @param password string
   * @param returnTarget
   * @param loginWithOCI
   */
  login(username: string, password: string, returnTarget?: string, loginWithOCI?: boolean) {
    const token$ = this.http.post<TokenResponseInterface>(
      `${environment.baseUrl}login`,
      {
        username,
        password,
      }
    );

    token$.pipe(catchError(this.catchLoginError)).subscribe(
      (response) => {
        this.pass = password;
        this.token = response.data.access_token;
        AuthService.storeToken(this.token);
        this.tokenSubscription.next(this.token);
        this.user = Object.assign(new User(), response.data.user[0]);
        AuthService.storeUser(this.user);
        this.finalizeLoginProcess(loginWithOCI);
        this.watchTokenExpiration();
      },
      (error) => {
        this.logginservice.error(error, 'auth.service');
        this.uiService.showErrorMessage(
          this.translateService.instant('LOGIN_PAGE.MESSAGE.UNKNOWN_ERROR')
        );
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant(
            'LOGIN_PAGE.MESSAGE.UNKNOWN_ERROR'
          ),
          detail: this.translateService.instant(
            'GENERAL.RESPONSES.UPDATE_FAIL'
          ),
        });
        window.open(returnTarget, '_self')
        // if (loginWithOCI) {
        //   this.utilityService.reloadWindow();
        // }
      }
    );
  }

  finalizeLoginProcess(loginWithOCI?: boolean) {
    if (this.user != null) {
      this.setUserLanguage();
      this.currentUserService.updateCurrentUserSubject(this.user);
      this.redirectAfterLogin(loginWithOCI);
      if (
        !this.user.isValidPassword &&
        this.user.isValidPassword !== undefined
      ) {
        this.showResetPassword();
      }
      this.organizationManagementService
        .getCompany(this.user.companyId)
        .subscribe((e) => {
          this.company = e.data[0];
          //this.showPointSystemModal();
        });
    }
  }

  /**
   * logout the current User and remove stored item (user inf and token) fron localStorage
   */
  logout(): void {
    if (this.user) {
      this.user = new User();
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      this.message = '';
      this.http
        .get<GenericResponseInterface>(`${environment.baseUrl}logout`)
        .subscribe(
          () => {
            this.uiService.showSuccessMessage(
              this.translateService.instant('LOGIN_PAGE.MESSAGE.LOGOUT_SUCESS')
            );
          },
          (error) => {
            this.logginservice.error(error, 'auth.service');
            this.uiService.showErrorMessage(
              this.translateService.instant(
                'LOGIN_PAGE.MESSAGE.UNKNOWN_LOGOUT_ERROR'
              )
            );
          }
        );
      //window.location.replace('/login');
      window.location.href = this.currentUserService.getReturnTarget();
      localStorage.removeItem('OCIData');
      this.tokenSubscription.complete();
      this.currentUserService.endUserSubscription();
      // this.router.navigate(['login']);
    }
  }

  wakeUp(): User | null {
    const storedUser = localStorage.getItem('user');
    const storedToken = localStorage.getItem('token');
    if (storedToken && storedUser) {
      this.user = Object.assign(new User(), JSON.parse(storedUser));
      this.token = storedToken;
      this.tokenSubscription.next(this.token);
      this.jwtTimeOutService.startExpirationTracking();
      this.watchTokenExpiration();
    }
    return this.user;
  }

  private catchLoginError = (error: HttpErrorResponse) => {
    if (error.status === 429) {
      this.message = this.translateService.instant(
        'LOGIN_PAGE.MESSAGE.ACCOUNT_LOCKED'
      );
      this.formIsLocked = true;
    } else if (error.status === 401) {
      this.message = this.translateService.instant(
        'LOGIN_PAGE.MESSAGE.LOGOUT_SUCESS'
      );
    }
    return throwError(
      this.translateService.instant('LOGIN_PAGE.MESSAGE.UNKNOWN_LOGIN_ERROR')
    );
  };

  public static storeToken(token: string): void {
    localStorage.setItem('token', token);
  }

  private static storeUser(user: User): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  private redirectAfterLogin(loginWithOci?: boolean): void {
    if (loginWithOci) {
      this.router.navigate(['cases/getOffer']);
    } else {
      this.router.navigate(['dashboard']);
    }
    this.jwtTimeOutService.startExpirationTracking();
  }

  getUser(): Observable<GenericResponseInterface> {
    return this.http.get<GenericResponseInterface>(
      `${environment.baseUrl}currentUser`
    );
  }

  setUserLanguage() {
    if (this.user != null)
      if (this.user.language === 49) {
        this.translateService.use('de');
      } else {
        if (this.user.language !== 44) {
          this.updateUserLanguageToEnglish();
        }
        this.translateService.use('en');
      }
  }

  updateUserLanguageToEnglish() {
    if (this.user != null) {
      this.user.language = 44;
      this.currentUserService.upateCurrentUser(this.user);
      this.currentUserService.updateUser(this.user).subscribe(
        () => {
          this.logginservice.info(
            'Success on Http request updateUser',
            this.constructor.name + '.changeLang()'
          );
          if (this.user != null)
            this.currentUserService.updateCurrentUserLanguage(
              this.user.language
            );
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant(
              'GENERAL.RESPONSES.UPDATED_HEADER'
            ),
            detail: this.translateService.instant('GENERAL.RESPONSES.UPDATED'),
          });
        },
        (error) => {
          this.logginservice.error(
            'Error on Http request updateUser: ' + error,
            this.constructor.name + '.changeLang()'
          );
        }
      );
    }
  }

  /**
   * open Reset Pasword Dynamic Dialog as a popup
   */
  showResetPassword() {
    this.ref = this.dialogService.open(ResetPasswordComponent, {
      showHeader: true,
      width: '50%',
      contentStyle: { 'max-height': '600px', overflow: 'auto' },
      baseZIndex: 10000,
      closable: false,
    });
  }

  /**
   * get user by user id ;
   * @param jwtToken
   * @param userId userId number;
   * @returns UserResponseInterface
   */
  controllerLoginAsCustomer(jwtToken: string, userId: string) {
    this.token = jwtToken;
    return this.http
      .get<UserResponseInterface>(`${environment.baseUrl}users/` + userId)
      .subscribe(
        (res) => {
          this.user = Object.assign(new User(), res.data[0]);
          AuthService.storeUser(this.user);
          this.finalizeLoginProcess();
        },
        (err) => {
          this.logginservice.error(
            err,
            'app.component.ts -> ControllerLoginAsCustomer'
          );
        }
      );
  }

  /**
   * Watches if the token is already expired or expiring and if so is going to logout the user
   */
  watchTokenExpiration() {
    this.jwtTimeOutService.getTimeOutTimeStamp() <= 0 ? this.logout() : '';
    this.jwtTimeOutService
      .getTokenExpiredSubject()
      .subscribe((e) => (e ? this.logout() : ''));
  }

  /**
   * Extends the token
   */
  extendToken() {
    this.jwtTimeOutService.extendToken().subscribe((e) => {
      this.token = e.data.access_token;
      AuthService.storeToken(this.token);
      this.tokenSubscription.next(this.token);
    });
  }

  showPointSystemModal() {
    if (this.currentUserService.getUsersPermissions().CAN_EDIT_COMPANY_DATA) {
      this.confirmationService.confirm({
        header: '[Punktesytem]',
        message:
          '[Wir haben auf ein Punkterabattsystem umgestellt. Bitte treffen Sie Entscheidung über die Teilnahme ihrer Firma.]',
        accept: () => {
          this.organizationManagementService
            .updateCompany(this.company)
            .subscribe();
        },
        reject: () => {
          this.organizationManagementService
            .updateCompany(this.company)
            .subscribe();
        },
        closeOnEscape: true,
      });
    } else {
      this.confirmationService.confirm({
        header: '[Punktesytem]',
        message:
          '[Wir haben auf ein Punkterabattsystem umgestellt. Bitte informieren Sie jemanden der die Berechtigung hat ihre Firmendaten zu ändern um eine Entscheidung über die Teilnahme zu treffen.]',
        accept: () => {
          //Actual logic to perform a confirmation
        },
        closeOnEscape: true,
      });
    }
  }
}
