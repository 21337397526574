import { Item } from './item';

export class Category {
  category: string = '';
  items: Item[] = [];
  constructor(category?: string, items?: Item[]) {
    if (category) this.category = category;
    if (items) this.items = items;
  }
}
