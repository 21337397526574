import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './core/app/app.component';
import { AuthInterceptor } from './core/auth/auth.interceptor';
import { ResetPasswordComponent } from './core/components/reset-password/reset-password.component';
import { MenuComponent } from './core/gobal-menu/menu.component';
import { HeaderModule } from './core/header/header.module';
import { LogPublisherService } from './core/logging/log-publisher-service';
import { LogService } from './core/logging/log.service';
import { UrlService } from './core/services/url.service';
import { LoginComponent } from './modules/login/login.component';
import { UiModule } from './ui.module';
import { JwtModule } from '@auth0/angular-jwt';
import { MessageService } from 'primeng/api';
import { HttpErrorInterceptor } from './core/interceptors/http-error-interceptor';
import { ErrorHandlerService } from './core/services/error-handler.service';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    LoginComponent,
    ResetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    UiModule,
    HeaderModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem('access_token'),
      },
    }),
  ],
  providers: [
    LogService,
    LogPublisherService,
    UrlService,
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
