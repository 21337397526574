import { HttpClient } from '@angular/common/http';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WrapFormService {
  invokeMyMethod = new EventEmitter();

  constructor(private http: HttpClient) {}

  /**
   *
   * @param params
   */
  callMyMethod(params: any = 'anyValue') {
    this.invokeMyMethod.emit(params);
  }
}
