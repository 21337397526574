import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HelpComponent } from 'src/app/core/components/help/help.component';
import { EventModel } from 'src/app/core/model/event-model/event.model';
import { NewEventsNumber } from 'src/app/core/model/event-model/newEventsNumber.model';
import { ChangePasswordComponent } from 'src/app/modules/organization-managmnet/pages/change-password/change-password.component';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../../auth/auth.service';
import { LogService } from '../../../logging/log.service';
import { CurrentUserService } from '../../../services/current-user.service';
import { AgbService } from '../../services/agb.service';
import { HeaderService } from '../../services/header.service';
import { User } from '../../../model/user.model';
@Component({
  selector: 'app-header-main',
  templateUrl: './header-main.component.html',
  styleUrls: ['./header-main.component.scss'],
})
export class HeaderMainComponent implements OnInit {
  public searchword = '';
  public companyName: string = '';
  public ref: DynamicDialogRef | null = null;
  public activeMenuIndex: number = -1;
  public outsideClickListener: any;
  public newNotificationNum: string = '';
  public personMenuItems: MenuItem[] = [];
  public selectedNotification: EventModel | null = null;
  public user: User = new User();
  public allNewNotification: EventModel[] = [];

  constructor(
    private router: Router,
    private logger: LogService,
    private agbService: AgbService,
    private authService: AuthService,
    public dialogService: DialogService,
    private headerService: HeaderService,
    private messageService: MessageService,
    public translateService: TranslateService,
    private currentUserService: CurrentUserService
  ) {}

  ngOnInit(): void {
    this.loadUser();
    this.setMenuItems();
    this.translateService.onLangChange.subscribe(() => this.setMenuItems());
    this.getNotificationsNumberWithInterval();
    this.getAllNotification();
  }

  loadUser() {
    this.currentUserService.userBehaviorSubject.subscribe((e) => {
      this.user = e;
    });
  }

  /**
   * change the project GUI language and update the user langage in DB
   * @param lang the languge which the user select from langMenu here we have 'en' and 'de'
   */
  changeLang(lang: string): void {
    let langCode: number = 0;
    if (lang === 'de') {
      langCode = 49;
      this.translateService.use('de');
    } else if (lang === 'en') {
      langCode = 44;
      this.translateService.use('en');
    }
    const storedUser = this.currentUserService.getUser();
    if (storedUser) {
      storedUser.language = langCode;
      this.currentUserService.updateUser(storedUser).subscribe(
        (res) => {
          this.logger.info(
            'Success on Http request updateUser',
            this.constructor.name + '.changeLang()',
            res
          );
          this.currentUserService.updateCurrentUserLanguage(langCode);
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant(
              'GENERAL.RESPONSES.UPDATED_HEADER'
            ),
            detail: this.translateService.instant('GENERAL.RESPONSES.UPDATED'),
          });
        },
        (error) => {
          this.logger.error(
            'Error on Http request updateUser',
            this.constructor.name + '.changeLang()',
            error
          );
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant(
              'GENERAL.RESPONSES.UPDATE_FAIL_HEADER'
            ),
            detail: this.translateService.instant(
              'GENERAL.RESPONSES.UPDATE_FAIL'
            ),
          });
        }
      );
    }
  }

  /**
   *download the AGB as a PDF from agbService
   */
  downloadAGB(): void {
    this.agbService.download();
  }

  /**
   * logout the user using the logout methode in AuthService
   */
  logout(): void {
    this.authService.logout();
  }

  /**
   * send the Notification request API every X time to get
   * always the new notification number from backend
   */
  getNotificationsNumberWithInterval() {
    this.getNewNotificationNumber();
    setInterval(() => {
      this.getNewNotificationNumber();
    }, environment.notificationsInterval);
  }

  /**
   * send the Notification request API and get
   *  new notification number from backend as a response
   */
  getNewNotificationNumber() {
    this.headerService.getNewNotificationNumber().subscribe((response) => {
      const newNotificationNumbers: NewEventsNumber[] = response.data;
      if (
        newNotificationNumbers &&
        newNotificationNumbers[0].count.toString() !== this.newNotificationNum
      ) {
        this.newNotificationNum = newNotificationNumbers[0].count.toString();
        this.getAllNotification();
      }
    });
  }

  /**
   * get all New Notificatons Objects from the backend
   */
  getAllNotification() {
    this.headerService.getAllNewNotification().subscribe(
      (response) => {
        this.allNewNotification = response.data.reverse();
        if (this.allNewNotification.length === 0) {
          const standatNotification: EventModel = {} as EventModel;
          standatNotification.caseId = 0;
          standatNotification.pot = new Date();
          standatNotification.remarks = this.translateService.instant(
            'HEADER.NO_NEW_NOTIFICATION'
          );
          this.allNewNotification.push(standatNotification);
        }
      },
      (error) => {
        this.logger.error(
          'Erro on Http request getAllNotification',
          this.constructor.name + '.getAllNotification()',
          error
        );
        const standatNotification: EventModel = {} as EventModel;
        standatNotification.caseId = 0;
        standatNotification.pot = new Date();
        standatNotification.remarks = this.translateService.instant(
          'HEADER.NO_NEW_NOTIFICATION'
        );
        this.allNewNotification.push(standatNotification);
      }
    );
  }

  /**
   * cancel the Notification and update its status to seen in Backend
   * @param notification the Nofications the need to Cancel
   */
  cancelNotification(notification: EventModel) {
    this.updateNotificationToSeen(notification);
  }

  /**
   * when the user select a notification from the menue come to hier
   * and update the selected notification to seen and navigate
   * to the right Notification case page
   * @param event
   * @param notification the Nofications that the user selecte it
   */
  onRowSelect(_event: any, notification: EventModel) {
    this.updateNotificationToSeen(notification);
    this.navigateToCase(notification);
    window.scrollTo(0, 0);
  }

  /**
   *send update request to Backend to update the selected Notification to seen .
   * @param notification
   */
  updateNotificationToSeen(notification: EventModel) {
    this.headerService
      .updateNotificationToSeen(notification.id, false)
      .subscribe(
        () => {
          this.getNewNotificationNumber();
          const index = this.allNewNotification.indexOf(notification, 0);
          this.allNewNotification.splice(index, 1);
          this.getAllNotification();
        },
        (error) => {
          this.logger.error(
            'Erro on Http request update Notification To Seen',
            this.constructor.name + '.updateNotificationToSeen()',
            error
          );
        }
      );
  }

  /**
   * send a request to Backend and get the Case By Id to checking if the case is Order of offer then navigate to right page.
   * @param notification
   */
  private navigateToCase(notification: EventModel) {
    if (notification.caseId !== 0) {
      this.headerService.getCaseById(notification.caseId).subscribe(
        (res) => {
          if (
            (res.data[0].caseStatus === 0 || res.data[0].caseStatus === 7) &&
            res.data[0].poStatus === 0
          ) {
            this.reRouteToCase(
              'cases/offers/',
              notification.caseId,
              notification.remarks !== ''
            );
          } else if (
            (res.data[0].caseStatus !== 0 && res.data[0].caseStatus !== 7) ||
            res.data[0].poStatus !== 0
          ) {
            this.reRouteToCase(
              'cases/orders/',
              notification.caseId,
              notification.remarks !== ''
            );
          }
        },
        (error) => {
          this.logger.error(
            'Error on Http request getCaseById in HeaderConponent ',
            this.constructor.name + '.navigateToCase()',
            error
          );
          this.messageService.add({
            severity: 'error',
            summary: this.translateService.instant(
              'NEW_CASE_ORDER.ORDER_PROCESS_FAILED_HEADER'
            ),
            detail: this.translateService.instant(
              'NEW_CASE_ORDER.ORDER_PROCESS_FAILED_MESSAGE'
            ),
          });
        }
      );
    }
  }

  /**
   * Reroutes to the case related to the notification and decided if the events-panel should be expanded and scrolled into
   * @param route case or offer route
   * @param caseId caseId
   * @param scrollToMessage if the events-panel should be expanded and scrolled into
   */
  reRouteToCase(route: string, caseId: number, scrollToMessage: boolean) {
    if (scrollToMessage) {
      this.router.navigate([route + caseId.toString()], {
        queryParams: { showEvents: 'true' },
      });
    } else {
      this.router.navigate([route + caseId.toString()]);
    }
  }

  /**
   * set the Menu Items and call it in NgOnInit
   */
  setMenuItems(): void {
    this.personMenuItems = [
      {
        items: [
          {
            label: this.translateService.instant(
              'HEADER.PERSONAL_MENU.COMPANY'
            ),
            icon: 'pi pi-fw pi-briefcase',
            command: () => this.router.navigate(['organizationManagment']),
            disabled: this.currentUserService.isOCIProcedureActive(),
          },
        ],
      },
      {
        items: [
          {
            label: this.translateService.instant(
              'HEADER.PERSONAL_MENU.PROFILE'
            ),
            icon: 'pi pi-fw pi-user',
            command: () =>
              this.router.navigate(['organizationManagment/profile']),
            disabled: this.currentUserService.isOCIProcedureActive(),
          },
        ],
      },
      {
        items: [
          {
            label: this.translateService.instant(
              'HEADER.PERSONAL_MENU.PASSWORD'
            ),
            icon: 'pi pi-fw pi-pencil',

            command: () => this.showEditPassword(),
            disabled: this.currentUserService.isOCIProcedureActive(),
          },
        ],
      },
      {
        label: this.translateService.instant('GENERAL.LANGUAGE'),
        items: [
          {
            label: this.translateService.instant('GENERAL.LANGUAGES.DE'),
            icon: 'flag flag-de',
            command: () => this.changeLang('de'),
          },
          {
            label: this.translateService.instant('GENERAL.LANGUAGES.EN'),
            icon: 'flag flag-gb',
            command: () => this.changeLang('en'),
          },
        ],
      },
      {
        items: [
          {
            label: this.translateService.instant('HEADER.PERSONAL_MENU.AGB'),
            icon: 'pi pi-fw pi-file-pdf',
            command: () => this.downloadAGB(),
          },
        ],
      },
      {
        items: [
          {
            label: this.translateService.instant('HEADER.PERSONAL_MENU.HELP'),
            icon: 'pi pi-question-circle',
            command: () => this.openHelpPage(),
          },
        ],
      },
      {
        items: [
          {
            label: this.translateService.instant('HEADER.PERSONAL_MENU.LOGOUT'),
            icon: 'pi pi-fw pi-sign-out',
            command: () => this.logout(),
          },
        ],
      },
    ];
  }

  /**
   * open Edit Pasword Dynamic Dialog as a popup
   */
  showEditPassword() {
    this.ref = this.dialogService.open(ChangePasswordComponent, {
      header: this.translateService.instant('HEADER.PERSONAL_MENU.PASSWORD'),
      showHeader: true,
      width: '50%',
      contentStyle: { 'max-height': '500px', overflow: 'auto' },
      baseZIndex: 10000,
    });
  }

  openHelpPage() {
    this.ref = this.dialogService.open(HelpComponent, {
      header: this.translateService.instant('HEADER.PERSONAL_MENU.HELP'),
      data: {
        scroolTo: 'content',
      },
      showHeader: true,
      width: '85%',
    });
  }
}
