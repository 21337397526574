<div id="header" class="justify-content-between header layout-topbar">
  <div class="col-4 align-items-center">
    <a [routerLink]="['/']" class="logo">
      <img alt="logo" [src]="'assets/img/leginda-logo.png'" />
    </a>
  </div>
  <!--  TODO: SUche wieder einblenden und implementieren -->
  <!-- <div class="col-6 ui-fluid">-->
  <!--    <div class="fluid">-->
  <!--      <div class="field grid search-container">-->
  <!--        <div class="col-12 md-10 search-container">-->
  <!--                 <span class="search-container input-icon-right input-icon-left ui-fluid search-container">-->
  <!--                  <i class="pi pi-info-circle"></i>-->
  <!--                  <i class="pi pi-search"></i>-->
  <!--                  <input class="ui-fluid" type="text" id="search-bar" pInputText ngModel="searchword" placeholder="Suche">-->
  <!--                </span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div> -->
  <div class="col flex justify-content-end text-right">
    <div class="text-right">
      <label class="font_normal fontColorWhite"
        >{{ user.lastName }}, {{ user.firstName }}</label
      ><br />
      <label class="font_small_info fontColorLightgrey">{{
        user.company
      }}</label>
    </div>

    <div class="align-content-end mr-2 ml-2">
      <p-button
        class="mr-1 shadow-4"
        tooltipPosition="bottom"
        pTooltip="{{ 'HEADER.NOTIFICATION' | translate }}"
        [badge]="newNotificationNum"
        badgeClass=""
        (onClick)="getAllNotification(); op.toggle($event)"
      >
        <i class="pi pi-inbox"></i>
      </p-button>
      <p-overlayPanel class="text-left" #op>
        <ng-template pTemplate="content">
          <p-table
            [value]="allNewNotification"
            [scrollable]="true"
            [autoLayout]="true"
            [style]="{ width: '28rem' }"
            scrollHeight="50rem"
            selectionMode="single"
            [paginator]="false"
            [responsive]="true"
            class=""
          >
            <ng-template pTemplate="body" let-rowData let-notification class="">
              <tr>
                <td class="shadow-4 col m-1 borderNotification">
                  <div class="p-fluid col">
                    <i
                      *ngIf="notification.caseId !== 0"
                      class="pi pi-times cancelNotification"
                      (click)="cancelNotification(notification)"
                    ></i>
                    <div>
                      <p class="ml-2 mb-0 font_normal_bold">
                        <u
                          >{{
                            notification.eventTypeId | eventType | translate
                          }}
                        </u>
                      </p>
                      <div class="ml-2 mb-2 font_small_info">
                        {{
                          notification?.pot?.toString()
                            | date: "dd.MM.yyyy HH:mm"
                        }}
                      </div>
                      <div class="overFlowRemarks ml-2 font_normal">
                        {{ notification.remarks }}
                      </div>
                      <div class="pr-2 pl-2">
                        <p-button
                          *ngIf="notification.caseId !== 0"
                          label="{{ 'HEADER.GO_TO' | translate }} ({{
                            notification.caseId
                          }})"
                          icon="pi pi-external-link"
                          class="shadow-4"
                          iconPos="right"
                          (onClick)="
                            onRowSelect($event, notification); op.hide()
                          "
                        >
                        </p-button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </ng-template>
      </p-overlayPanel>
      <button
        tooltipPosition="bottom"
        pTooltip="{{ 'HEADER.PERSONAL_MENU.TOOLTIP' | translate }}"
        type="button"
        class="button shadow-4 text-left"
        pButton
        pRipple
        icon="pi pi-user"
        (click)="menu.toggle($event)"
      ></button>
      <p-menu
        #menu
        class="text-left"
        [popup]="true"
        [model]="personMenuItems"
      ></p-menu>
    </div>
  </div>
</div>
