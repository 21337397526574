import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from './../../ui.module';
import { HeaderMainComponent } from './pages/header-main/header-main.component';

@NgModule({
  declarations: [HeaderMainComponent],
  imports: [CommonModule, UiModule, TranslateModule],
  exports: [HeaderMainComponent],
})
export class HeaderModule {}
