import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { LoginComponent } from './modules/login/login.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (module) => module.DashboardModule
      ),
    canLoad: [AuthGuard],
    data: {
      permission: '',
    },
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (module) => module.DashboardModule
      ),
    canLoad: [AuthGuard],
    data: {
      permission: 'view_Home',
    },
  },

  {
    path: 'cases',
    loadChildren: () =>
      import('./modules/cases/cases.module').then(
        (module) => module.CasesModule
      ),
    canLoad: [AuthGuard],
    data: {
      permission: 'view_Home',
    },
  },
  {
    path: 'bills',
    loadChildren: () =>
      import('./modules/billing/billing.module').then(
        (module) => module.BillingModule
      ),
    canLoad: [AuthGuard],
    data: {
      permission: 'view_Home',
    },
  },
  {
    path: 'feedback',
    loadChildren: () =>
      import('./modules/feedback/feedback.module').then(
        (module) => module.FeedbackModule
      ),
    canLoad: [AuthGuard],
    data: {
      permission: 'view_Home',
    },
  },
  {
    path: 'organizationManagment',
    loadChildren: () =>
      import(
        './modules/organization-managmnet/organization-managmnet.module'
      ).then((module) => module.OrganizationManagmnetModule),
    canLoad: [AuthGuard],
    data: {
      permission: '',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
