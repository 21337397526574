import { Subscription } from 'rxjs';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[legindaCompare]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CompareValidatorDirective,
      multi: true,
    },
  ],
})
export class CompareValidatorDirective implements Validators {
  @Input() legindaCompare: string = '';

  validate(c: AbstractControl): ValidationErrors | null {
    const controlToCompare = c.root.get(this.legindaCompare);
    if (controlToCompare) {
      const subscription: Subscription =
        controlToCompare.valueChanges.subscribe(() => {
          c.updateValueAndValidity();
          subscription.unsubscribe();
        });
    }
    return controlToCompare && controlToCompare.value !== c.value
      ? { compare: true }
      : null;
  }
}
