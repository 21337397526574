import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(public translator: TranslateService) {}

  /**
   * get strin to translate and return the suitable translation.
   * @param param string to translate
   * @returns a translation string
   */
  getTranslation(param: string): string {
    let value: string = 'TRANSLATION-ERROR';
    this.translator
      .get(param)
      .subscribe((translation) => (value = translation));
    return value;
  }

  /**
   * Switch the translator language between English and German
   * @param lang the language code number as a number.
   */
  changeLangById(lang: number) {
    if (lang === 49) {
      this.translator.use('de');
    } else if (lang === 44) {
      this.translator.use('en');
    }
  }

  /**
   * Returns the current language abbreviation which is set for ngx-translate
   */
  getCurrentLanguage(): string {
    return this.translator.currentLang;
  }

  /**
   * Returns the current language symbol
   */
  getCurrentLanguageLocaleCode(): string {
    return this.getCurrentLanguage() === 'de' ? 'de-DE' : 'en-US';
  }
}
