<p-fieldset id="content" [toggleable]="true" class="p-md-2">
  <ng-template pTemplate="header">
    {{ "HELP_PAGE.TABLE_OF_CONTENTS" | translate }}
  </ng-template>
  <!-- <p-fieldset legend="GENERAL INFORMATION" [toggleable]="true" class="p-md-2"> -->
  <div (click)="scroll('workspace')" class="divReference">
    {{ "HELP_PAGE.CHAPTERS.WORKSPACE.HEADLINE" | translate }}
  </div>
  <div (click)="scroll('priceQuote')" class="divReference">
    {{ "HELP_PAGE.CHAPTERS.GET_OFFER.HEADLINE" | translate }}
  </div>
  <div (click)="scroll('TranslationComplation')" class="divReference">
    {{ "HELP_PAGE.CHAPTERS.ORDER_COMPLETED.HEADLINE" | translate }}
  </div>
  <div (click)="scroll('orderList')" class="divReference">
    {{ "HELP_PAGE.CHAPTERS.ORDERLIST.HEADLINE" | translate }}
  </div>
  <div (click)="scroll('createAdditionalUsers')" class="divReference">
    {{ "HELP_PAGE.CHAPTERS.ADD_USERS.HEADLINE" | translate }}
  </div>
  <div (click)="scroll('changeCompanyOrBilling')" class="divReference">
    {{ "HELP_PAGE.CHAPTERS.CHANGE_ADRESSES.HEADLINE" | translate }}
  </div>
  <div (click)="scroll('addAdditionalBilling')" class="divReference">
    {{ "HELP_PAGE.CHAPTERS.ADD_BILLING_ADRESS.HEADLINE" | translate }}
  </div>
  <div (click)="scroll('changePassword')" class="divReference">
    {{ "HELP_PAGE.CHAPTERS.CHANGE_PASSWORD.HEADLINE" | translate }}
  </div>
  <div (click)="scroll('emailSetting')" class="divReference">
    {{ "HELP_PAGE.CHAPTERS.EMAIL_SETTINGS.HEADLINE" | translate }}
  </div>
  <div (click)="scroll('feedback')" class="divReference">
    {{ "HELP_PAGE.CHAPTERS.FEEDBACK.HEADLINE" | translate }}
  </div>
  <div (click)="scroll('argumentApply')" class="divReference">
    <a>{{ "HELP_PAGE.CHAPTERS.TERMS.HEADLINE" | translate }}</a>
  </div>
  <div (click)="scroll('logout')" class="divReference">
    <a>{{ "HELP_PAGE.CHAPTERS.LOGOUT.HEADLINE" | translate }}</a>
  </div>
  <div (click)="scroll('faq')" class="divReference">
    <a>{{ "HELP_PAGE.CHAPTERS.FAQ.HEADLINE" | translate }}</a>
  </div>
</p-fieldset>

<p-fieldset id="workspace" [toggleable]="true" class="md-2">
  <ng-template pTemplate="header">
    {{ "HELP_PAGE.CHAPTERS.WORKSPACE.HEADLINE" | translate }}
  </ng-template>
  <img
    class="full-size-image"
    src="{{
      '../../../../assets/img/help/Picture1_' +
        translateService.currentLang +
        '.png'
    }}"
    alt=""
  />
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.WORKSPACE.CONTENT1' | translate"></p>

  <p-divider></p-divider>

  <div class="flex">
    <img
      style="height: 25em; width: auto"
      src="{{
        '../../../../assets/img/help/Picture2_' +
          translateService.currentLang +
          '.png'
      }}"
      alt=""
    />
    <div class="flex flex-column justify-content-center ml-6">
      <p [innerHTML]="'HELP_PAGE.CHAPTERS.WORKSPACE.CONTENT2' | translate"></p>
    </div>
  </div>
  <div class="flex flex-row justify-content-center">
    <div class="flex flex-row justify-content-center">
      <div (click)="scroll('content')" class="divReference">
        {{ "HELP_PAGE.BACK_TO_TOP" | translate }}
      </div>
    </div>
  </div>
</p-fieldset>

<p-fieldset id="priceQuote" [toggleable]="true" class="md-2">
  <ng-template pTemplate="header">
    {{ "HELP_PAGE.CHAPTERS.GET_OFFER.HEADLINE" | translate }}
  </ng-template>
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.GET_OFFER.CONTENT1' | translate"></p>
  <img
    class="full-size-image"
    src="{{
      '../../../../assets/img/help/Picture3_' +
        translateService.currentLang +
        '.png'
    }}"
    alt=""
  />
  <p
    id="newCaseOverviewHelpNote"
    [innerHTML]="'HELP_PAGE.CHAPTERS.GET_OFFER.CONTENT2' | translate"
  ></p>
  <img
    class="full-size-image"
    src="{{
      '../../../../assets/img/help/Picture4_' +
        translateService.currentLang +
        '.png'
    }}"
    alt=""
  />
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.GET_OFFER.CONTENT3' | translate"></p>

  <img
    class="full-size-image"
    src="{{
      '../../../../assets/img/help/Picture5_' +
        translateService.currentLang +
        '.png'
    }}"
    alt=""
  />
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.GET_OFFER.CONTENT4' | translate"></p>
  <img
    class="full-size-image"
    src="{{
      './assets/img/help/Picture6_' + translateService.currentLang + '.png'
    }}"
    alt=""
  />
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.GET_OFFER.CONTENT5' | translate"></p>

  <img
    class="full-size-image"
    src="{{
      './assets/img/help/Picture7_' + translateService.currentLang + '.png'
    }}"
    alt=""
  />
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.GET_OFFER.CONTENT6' | translate"></p>
  <img
    class="full-size-image"
    src="{{
      './assets/img/help/Picture8_' + translateService.currentLang + '.png'
    }}"
    alt=""
  />
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.GET_OFFER.CONTENT7' | translate"></p>
  <img
    class="full-size-image"
    src="{{
      './assets/img/help/Picture9_' + translateService.currentLang + '.png'
    }}"
    alt=""
  />
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.GET_OFFER.CONTENT8' | translate"></p>

  <div class="flex flex-row justify-content-center">
    <div (click)="scroll('content')" class="divReference">
      {{ "HELP_PAGE.BACK_TO_TOP" | translate }}
    </div>
  </div>
</p-fieldset>

<p-fieldset id="TranslationComplation" [toggleable]="true" class="md-2">
  <ng-template pTemplate="header">
    {{ "HELP_PAGE.CHAPTERS.ORDER_COMPLETED.HEADLINE" | translate }}
  </ng-template>

  <!-- <p>In this column, which is marked by the icon <img src="{{'./assets/img/help/Picture9_'+translateService.currentLang+'.png'}}" alt="Auftragsliste"
      title="Auftragsliste">, an overview of current and completed orders is displayed.</p> -->
  <p
    class=""
    [innerHTML]="'HELP_PAGE.CHAPTERS.ORDER_COMPLETED.CONTENT1' | translate"
  ></p>
  <img
    class="full-size-image"
    src="{{
      './assets/img/help/Picture10_' + translateService.currentLang + '.png'
    }}"
    alt=""
  />
  <p
    class=""
    [innerHTML]="'HELP_PAGE.CHAPTERS.ORDER_COMPLETED.CONTENT2' | translate"
  ></p>
  <div class="flex flex-row justify-content-center">
    <div (click)="scroll('content')" class="divReference">
      {{ "HELP_PAGE.BACK_TO_TOP" | translate }}
    </div>
  </div>
</p-fieldset>

<p-fieldset id="orderList" [toggleable]="true" class="md-2">
  <ng-template pTemplate="header">
    {{ "HELP_PAGE.CHAPTERS.ORDERLIST.HEADLINE" | translate }}
  </ng-template>

  <!-- <p>Select the button <b>"Manage Users" - <img src="{{'res/benutzer_verwalten_medium_'+translateService.currentLang+'.png'}}" alt="User" title="User"></b>.
    An overview window will open which contains all company data and an overview of users.</p> -->
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.ORDERLIST.CONTENT' | translate"></p>
  <div class="flex flex-row justify-content-center">
    <div (click)="scroll('content')" class="divReference">
      {{ "HELP_PAGE.BACK_TO_TOP" | translate }}
    </div>
  </div>
</p-fieldset>

<p-fieldset id="createAdditionalUsers" [toggleable]="true" class="md-2">
  <ng-template pTemplate="header">
    {{ "HELP_PAGE.CHAPTERS.ADD_USERS.HEADLINE" | translate }}
  </ng-template>

  <!-- <p>In the main menu, please go to the icon <b>"Manage users" - <img src="{{'res/benutzer_verwalten_medium_'+translateService.currentLang+'.png'}}" alt="User" title="User"></b> and then to <b>"Edit Company" - <img src="{{'res/firma_bearbeiten_32_'+translateService.currentLang+'.png'}}" alt="Address" title="Address"></b>. Select the tab <b>"Billing Addresses"</b>. All existing invoice addresses are displayed in the overview that opens here.  Via the icon <b>"Create Invoice Address"  <img src="{{'res/rechnungsadresse_erstellen_medium_'+translateService.currentLang+'.png'}}" alt="Address" title="Address"></b> new or additional invoice addresses can be specified.  Additionally, icons are contained in the list of existing invoice addresses with which these can be changed or deleted.</p> -->
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.ADD_USERS.CONTENT1' | translate"></p>

  <img
    class="full-size-image"
    src="{{
      './assets/img/help/Picture11_' + translateService.currentLang + '.png'
    }}"
    alt=""
  />
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.ADD_USERS.CONTENT2' | translate"></p>
  <img
    class="full-size-image"
    src="{{
      './assets/img/help/Picture12_' + translateService.currentLang + '.png'
    }}"
    alt=""
  />
  <p></p>
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.ADD_USERS.CONTENT3' | translate"></p>
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.ADD_USERS.CONTENT5' | translate"></p>
  <img
    class="full-size-image"
    src="{{
      './assets/img/help/Picture13_' + translateService.currentLang + '.png'
    }}"
    alt=""
  />
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.ADD_USERS.CONTENT5' | translate"></p>
  <div class="flex flex-row justify-content-center">
    <div (click)="scroll('content')" class="divReference">
      {{ "HELP_PAGE.BACK_TO_TOP" | translate }}
    </div>
  </div>
</p-fieldset>

<p-fieldset id="changeCompanyOrBilling" [toggleable]="true" class="md-2">
  <ng-template pTemplate="header">
    {{ "HELP_PAGE.CHAPTERS.CHANGE_ADRESSES.HEADLINE" | translate }}
  </ng-template>

  <p></p>
  <p
    [innerHTML]="'HELP_PAGE.CHAPTERS.CHANGE_ADRESSES.CONTENT1' | translate"
  ></p>
  <img
    class="full-size-image"
    src="{{
      './assets/img/help/Picture14_' + translateService.currentLang + '.png'
    }}"
    alt=""
  />
  <div class="flex flex-row justify-content-center">
    <div (click)="scroll('content')" class="divReference">
      {{ "HELP_PAGE.BACK_TO_TOP" | translate }}
    </div>
  </div>
</p-fieldset>

<p-fieldset id="addAdditionalBilling" [toggleable]="true" class="md-2">
  <ng-template pTemplate="header">
    {{ "HELP_PAGE.CHAPTERS.ADD_BILLING_ADRESS.HEADLINE" | translate }}
  </ng-template>

  <p
    [innerHTML]="'HELP_PAGE.CHAPTERS.ADD_BILLING_ADRESS.CONTENT1' | translate"
  ></p>
  <img
    class="full-size-image"
    src="{{
      './assets/img/help/Picture15_' + translateService.currentLang + '.png'
    }}"
    alt=""
  />
  <p
    [innerHTML]="'HELP_PAGE.CHAPTERS.ADD_BILLING_ADRESS.CONTENT2' | translate"
  ></p>
  <img
    class="full-size-image"
    src="{{
      './assets/img/help/Picture16_' + translateService.currentLang + '.png'
    }}"
    alt=""
  />

  <div class="flex flex-row justify-content-center">
    <div (click)="scroll('content')" class="divReference">
      {{ "HELP_PAGE.BACK_TO_TOP" | translate }}
    </div>
  </div>
</p-fieldset>

<p-fieldset id="changePassword" [toggleable]="true" class="md-2">
  <ng-template pTemplate="header">
    {{ "HELP_PAGE.CHAPTERS.CHANGE_PASSWORD.HEADLINE" | translate }}
  </ng-template>
  <p
    [innerHTML]="'HELP_PAGE.CHAPTERS.CHANGE_PASSWORD.CONTENT1' | translate"
  ></p>
  <img
    src="{{
      './assets/img/help/Picture17_' + translateService.currentLang + '.png'
    }}"
    alt=""
  />
  <p
    [innerHTML]="'HELP_PAGE.CHAPTERS.CHANGE_PASSWORD.CONTENT2' | translate"
  ></p>
  <div class="flex flex-row justify-content-center">
    <div (click)="scroll('content')" class="divReference">
      {{ "HELP_PAGE.BACK_TO_TOP" | translate }}
    </div>
  </div>
</p-fieldset>

<p-fieldset id="emailSetting" [toggleable]="true" class="md-2">
  <ng-template pTemplate="header">
    {{ "HELP_PAGE.CHAPTERS.EMAIL_SETTINGS.HEADLINE" | translate }}
  </ng-template>
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.EMAIL_SETTINGS.CONTENT1' | translate"></p>
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.EMAIL_SETTINGS.CONTENT2' | translate"></p>

  <img
    class="full-size-image"
    src="{{
      './assets/img/help/Picture18_' + translateService.currentLang + '.png'
    }}"
    alt=""
  />
  <div class="flex flex-row justify-content-center">
    <div (click)="scroll('content')" class="divReference">
      {{ "HELP_PAGE.BACK_TO_TOP" | translate }}
    </div>
  </div>
</p-fieldset>

<p-fieldset id="feedback" [toggleable]="true" class="md-2">
  <ng-template pTemplate="header">
    {{ "HELP_PAGE.CHAPTERS.FEEDBACK.HEADLINE" | translate }}
  </ng-template>
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.FEEDBACK.CONTENT1' | translate"></p>

  <img
    class="full-size-image"
    src="{{
      './assets/img/help/Picture19_' + translateService.currentLang + '.png'
    }}"
    alt=""
  />
  <div class="flex flex-row justify-content-center">
    <div (click)="scroll('content')" class="divReference">
      {{ "HELP_PAGE.BACK_TO_TOP" | translate }}
    </div>
  </div>
</p-fieldset>

<p-fieldset id="argumentApply" [toggleable]="true" class="md-2">
  <ng-template pTemplate="header">
    {{ "HELP_PAGE.CHAPTERS.TERMS.HEADLINE" | translate }}
  </ng-template>
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.TERMS.CONTENT1' | translate"></p>

  <div class="flex flex-row justify-content-center">
    <div (click)="scroll('content')" class="divReference">
      {{ "HELP_PAGE.BACK_TO_TOP" | translate }}
    </div>
  </div>
</p-fieldset>

<p-fieldset id="logout" [toggleable]="true" class="md-2">
  <ng-template pTemplate="header">
    {{ "HELP_PAGE.CHAPTERS.LOGOUT.HEADLINE" | translate }}
  </ng-template>
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.LOGOUT.CONTENT1' | translate"></p>

  <div class="flex flex-row justify-content-center">
    <div (click)="scroll('content')" class="divReference">
      {{ "HELP_PAGE.BACK_TO_TOP" | translate }}
    </div>
  </div>
</p-fieldset>

<p-fieldset id="faq" [toggleable]="true" class="md-2">
  <ng-template pTemplate="header">
    {{ "HELP_PAGE.CHAPTERS.FAQ.HEADLINE" | translate }}
  </ng-template>
  <p [innerHTML]="'HELP_PAGE.CHAPTERS.FAQ.CONTENT1' | translate"></p>

  <div class="flex flex-row justify-content-center">
    <div (click)="scroll('content')" class="divReference">
      {{ "HELP_PAGE.BACK_TO_TOP" | translate }}
    </div>
  </div>
</p-fieldset>
