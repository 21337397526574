import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'eventType',
})
export class EventTypePipe implements PipeTransform {
  eventType: string = '';

  /**
   * get the event Type as a number and retur the code string.
   * @param value the event Type number
   * @param args
   * @returns event Type Translation code string
   */
  transform(value: unknown, ..._args: unknown[]): string {
    switch (value) {
      case 1: {
        this.eventType = 'EVENT_TYPE.CREATED';
        return this.eventType;
      }

      case 2: {
        this.eventType = 'EVENT_TYPE.FORWARDED_BY_CUSTOMER';
        return this.eventType;
      }

      case 3: {
        this.eventType = 'EVENT_TYPE.OFFERED_TO_TRANSLATORS';
        return this.eventType;
      }

      case 4: {
        this.eventType = 'EVENT_TYPE.ACCEPTED_BY_TRANSLATOR';
        return this.eventType;
      }

      case 5: {
        this.eventType = 'EVENT_TYPE.FORWARDED_BY_TRANSLATOR';
        return this.eventType;
      }

      case 6: {
        this.eventType = 'EVENT_TYPE.REJECTED_BY_CONTROLLER';
        return this.eventType;
      }

      case 7: {
        this.eventType = 'EVENT_TYPE.FORWARDED_BY_CONTROLLER';
        return this.eventType;
      }

      case 8: {
        this.eventType = 'EVENT_TYPE.REJECTED_BY_CUSTOMER';
        return this.eventType;
      }

      case 9: {
        this.eventType = 'EVENT_TYPE.FINALIZED_BY_CUSTOMER';
        return this.eventType;
      }

      case 10: {
        this.eventType = 'EVENT_TYPE.REJECTED_BY_ALL_TRANSLATORS';
        return this.eventType;
      }

      case 11: {
        this.eventType = 'EVENT_TYPE.CALCULATION_REQUEST';
        return this.eventType;
      }

      case 12: {
        this.eventType = 'EVENT_TYPE.OFFER_SUBMIT';
        return this.eventType;
      }

      case 13: {
        this.eventType = 'EVENT_TYPE.REJECTED_BY_TRANSLATOR';
        return this.eventType;
      }

      case 14: {
        this.eventType = 'EVENT_TYPE.WITHDRAWN_FROM_CUSTOMER';
        return this.eventType;
      }

      case 15: {
        this.eventType = 'EVENT_TYPE.WITHDRAWN_FROM_TRANSLATORS';
        return this.eventType;
      }

      case 16: {
        this.eventType = 'EVENT_TYPE.MODIFIED_SALES_DATA';
        return this.eventType;
      }

      case 17: {
        this.eventType = 'EVENT_TYPE.CHANGED_CASE_BILLING_ADDRESS';
        return this.eventType;
      }

      case 18: {
        this.eventType = 'EVENT_TYPE.CHANGED_TITLE';
        return this.eventType;
      }

      case 19: {
        this.eventType = 'EVENT_TYPE.CHANGED_FIELD';
        return this.eventType;
      }

      case 20: {
        this.eventType = 'EVENT_TYPE.TRANSLATOR_INVOICE_AVAILABLE';
        return this.eventType;
      }

      case 21: {
        this.eventType = 'EVENT_TYPE.POSTCALCULATION';
        return this.eventType;
      }

      case 22: {
        this.eventType = 'EVENT_TYPE.REGENERATED_INVOICE';
        return this.eventType;
      }

      case 23: {
        this.eventType = 'EVENT_TYPE.UPLOADED_NEW_DOCUMENT';
        return this.eventType;
      }

      case 24: {
        this.eventType = 'EVENT_TYPE.CALCULATION';
        return this.eventType;
      }

      case 25: {
        this.eventType = 'EVENT_TYPE.CHANGED_DELIVERY_TYPE';
        return this.eventType;
      }

      case 26: {
        this.eventType = 'EVENT_TYPE.DIRECT_ORDER';
        return this.eventType;
      }

      case 27: {
        this.eventType = 'EVENT_TYPE.DIRETCLY_ORDERED_BY_CONTROLLER';
        return this.eventType;
      }

      case 28: {
        this.eventType = 'EVENT_TYPE.INDIVIDUAL_OFFER_SUBMITTED';
        return this.eventType;
      }

      case 29: {
        this.eventType = 'EVENT_TYPE.DECLINED_TRANSLATION_BY_CONTROLLER';
        return this.eventType;
      }

      case 30: {
        this.eventType = 'EVENT_TYPE.ACCEPTED_COMPLAINT_BY_TRANSLATOR';
        return this.eventType;
      }

      case 31: {
        this.eventType = 'EVENT_TYPE.REFUSED_COMPLAINT_BY_TRANSLATOR';
        return this.eventType;
      }

      case 32: {
        this.eventType = 'EVENT_TYPE.COMPLAINT_EMAIL_TO_TRANSLATOR_SENT';
        return this.eventType;
      }

      case 34: {
        this.eventType = 'EVENT_TYPE.OFFERED_TO_PROOFREADERS';
        return this.eventType;
      }

      case 35: {
        this.eventType = 'EVENT_TYPE.ACCEPTED_BY_PROOFREADER';
        return this.eventType;
      }

      case 36: {
        this.eventType = 'EVENT_TYPE.DECLINED_BY_PROOFREADER';
        return this.eventType;
      }

      case 37: {
        this.eventType = 'EVENT_TYPE.PROOFREADING_REJECTED_BY_CONTROLLER';
        return this.eventType;
      }

      case 38: {
        this.eventType = 'EVENT_TYPE.ACCEPTED_COMPLAINT_BY_PROOFREADER';
        return this.eventType;
      }

      case 39: {
        this.eventType = 'EVENT_TYPE.REFUSED_COMPLAINT_BY_PROOFREADER';
        return this.eventType;
      }

      case 40: {
        this.eventType = 'EVENT_TYPE.REJECTED_BY_ALL_PROOFREADERS';
        return this.eventType;
      }

      case 41: {
        this.eventType = 'EVENT_TYPE.FORWARDED_BY_PROOFREADER';
        return this.eventType;
      }

      case 42: {
        this.eventType = 'EVENT_TYPE.COMPLAINT_EMAIL_TO_PROOFREADER_SENT';
        return this.eventType;
      }

      case 43: {
        this.eventType = 'EVENT_TYPE.OLD_CONTROLLER_ANNOTATION_TRANSLATION';
        return this.eventType;
      }

      case 44: {
        this.eventType = 'EVENT_TYPE.OLD_CONTROLLER_ANNOTATION_PROOFREADING';
        return this.eventType;
      }

      case 45: {
        this.eventType = 'EVENT_TYPE.REACTIVATE_CASE';
        return this.eventType;
      }

      case 46: {
        this.eventType = 'EVENT_TYPE.REGEN_CREDIT_TRANSLATOR';
        return this.eventType;
      }

      case 47: {
        this.eventType = 'EVENT_TYPE.REGEN_CREDIT_PROOFREADER';
        return this.eventType;
      }

      case 50: {
        this.eventType = 'EVENT_TYPE.WITHDRAWN_FROM_PROOFREADER';
        return this.eventType;
      }

      case 51: {
        this.eventType = 'EVENT_TYPE.PROOFREADER_INVOICE_AVAILABLE';
        return this.eventType;
      }

      case 52: {
        this.eventType = 'EVENT_TYPE.CHANGE_LANGUAGE';
        return this.eventType;
      }

      case 53: {
        this.eventType = 'EVENT_TYPE.OFFERED_TO_TARGET_TRANSLATORS';
        return this.eventType;
      }

      case 54: {
        this.eventType = 'EVENT_TYPE.ACCEPTED_BY_TARGET_TRANSLATOR';
        return this.eventType;
      }

      case 55: {
        this.eventType = 'EVENT_TYPE.FORWARDED_BY_TARGET_TRANSLATOR';
        return this.eventType;
      }

      case 56: {
        this.eventType = 'EVENT_TYPE.REJECTED_BY_TARGET_TRANSLATOR';
        return this.eventType;
      }

      case 57: {
        this.eventType = 'EVENT_TYPE.REJECTED_BY_ALL_TARGET_TRANSLATORS';
        return this.eventType;
      }

      case 58: {
        this.eventType = 'EVENT_TYPE.WITHDRAWN_FROM_TARGET_TRANSLATORS';
        return this.eventType;
      }

      case 59: {
        this.eventType = 'EVENT_TYPE.DECLINED_TARGET_TRANSLATION_BY_CONTROLLER';
        return this.eventType;
      }

      case 60: {
        this.eventType = 'EVENT_TYPE.ACCEPTED_COMPAINT_BY_TARGET_TRANSLATOR';
        return this.eventType;
      }

      case 61: {
        this.eventType = 'EVENT_TYPE.REFUSED_COMPAINT_BY_TARGET_TRANSLATOR';
        return this.eventType;
      }

      case 62: {
        this.eventType = 'EVENT_TYPE.COMPLAINT_EMAIL_TO_TARGET_TRANSLATOR_SENT';
        return this.eventType;
      }

      case 63: {
        this.eventType =
          'EVENT_TYPE.OLD_CONTROLLER_ANNOTATION_TARGET_TRANSLATION';
        return this.eventType;
      }

      case 64: {
        this.eventType = 'EVENT_TYPE.REGENERATED_CREDIT_TARGET_TRANSLATOR';
        return this.eventType;
      }

      case 65: {
        this.eventType = 'EVENT_TYPE.OFFERED_TO_INTER_TRANSLATORS';
        return this.eventType;
      }

      case 66: {
        this.eventType = 'EVENT_TYPE.ACCEPTED_BY_INTER_TRANSLATOR';
        return this.eventType;
      }

      case 67: {
        this.eventType = 'EVENT_TYPE.FORWARDED_BY_INTER_TRANSLATOR';
        return this.eventType;
      }

      case 68: {
        this.eventType = 'EVENT_TYPE.REJECTED_BY_INTER_TRANSLATOR';
        return this.eventType;
      }

      case 69: {
        this.eventType = 'EVENT_TYPE.REJECTED_BY_ALL_INTER_TRANSLATORS';
        return this.eventType;
      }

      case 70: {
        this.eventType = 'EVENT_TYPE.WITHDRAWN_FROM_INTER_TRANSLATORS';
        return this.eventType;
      }

      case 71: {
        this.eventType = 'EVENT_TYPE.DECLINED_INTER_TRANSLATION_BY_CONTROLLER';
        return this.eventType;
      }

      case 72: {
        this.eventType = 'EVENT_TYPE.ACCEPTED_COMPAINT_BY_INTER_TRANSLATOR';
        return this.eventType;
      }

      case 73: {
        this.eventType = 'EVENT_TYPE.REFUSED_COMPAINT_BY_INTER_TRANSLATOR';
        return this.eventType;
      }

      case 74: {
        this.eventType = 'EVENT_TYPE.COMPLAINT_EMAIL_TO_INTER_TRANSLATOR_SENT';
        return this.eventType;
      }

      case 75: {
        this.eventType =
          'EVENT_TYPE.OLD_CONTROLLER_ANNOTATION_INTER_TRANSLATION';
        return this.eventType;
      }

      case 76: {
        this.eventType = 'EVENT_TYPE.REGENERATED_CREDIT_INTER_TRANSLATOR';
        return this.eventType;
      }

      case 77: {
        this.eventType = 'EVENT_TYPE.PAY_WITH_PAYONE';
        return this.eventType;
      }

      case 78: {
        this.eventType = 'EVENT_TYPE.ORDER_MACHINE_TRANSLATION';
        return this.eventType;
      }

      case 79: {
        this.eventType = 'EVENT_TYPE.CREATE_INVOICE_DRAFT';
        return this.eventType;
      }

      case 80: {
        this.eventType = 'EVENT_TYPE.INVOICE_DRAFT_VERIFIED';
        return this.eventType;
      }

      case 81: {
        this.eventType = 'EVENT_TYPE.TRADOS_SEND_PROOFPACKAGE';
        return this.eventType;
      }

      case 100: {
        this.eventType = 'EVENT_TYPE.CANCELED';
        return this.eventType;
      }

      case 101: {
        this.eventType = 'EVENT_TYPE.ARCHIVED';
        return this.eventType;
      }

      case 1002: {
        this.eventType = 'EVENT_TYPE.PUBLIC_COMMENT_CUSTOMER';
        return this.eventType;
      }

      case 1003: {
        this.eventType = 'EVENT_TYPE.PUBLIC_COMMENT_CONTROLLER';
        return this.eventType;
      }

      case 1004: {
        this.eventType = 'EVENT_TYPE.PUBLIC_COMMENT_ASSISTENT';
        return this.eventType;
      }

      case 1102: {
        this.eventType = 'EVENT_TYPE.PRIVATE_COMMENT_CUSTOMER';
        return this.eventType;
      }

      case 1103: {
        this.eventType = 'EVENT_TYPE.PRIVATE_COMMENT_CONTROLLER';
        return this.eventType;
      }

      case 1104: {
        this.eventType = 'EVENT_TYPE.PRIVATE_COMMENT_ASSISTENT';
        return this.eventType;
      }

      case 1105: {
        this.eventType = 'EVENT_TYPE.FEEDBACK';
        return this.eventType;
      }

      case 1106: {
        this.eventType = 'EVENT_TYPE.CREDIT_DEMAND';
        return this.eventType;
      }

      case 1107: {
        this.eventType = 'EVENT_TYPE.UPDATE_TRANSLATOR_LIST';
        return this.eventType;
      }

      case 1108: {
        this.eventType = 'EVENT_TYPE.UPDATE_PROOFREADER_LIST';
        return this.eventType;
      }

      case 1110: {
        this.eventType = 'EVENT_TYPE.MESSAGE_TO_CONTROLLER';
        return this.eventType;
      }

      case 1111: {
        this.eventType = 'EVENT_TYPE.MESSAGE_TO_CUSTOMER';
        return this.eventType;
      }

      case 1112: {
        this.eventType = 'EVENT_TYPE.MESSAGE_TO_TRANSLATOR';
        return this.eventType;
      }

      case 1113: {
        this.eventType = 'EVENT_TYPE.MESSAGE_TO_PROOFREADER';
        return this.eventType;
      }

      case 1114: {
        this.eventType = 'EVENT_TYPE.TM_UPDATED';
        return this.eventType;
      }
    }
    return this.eventType;
  }
}
