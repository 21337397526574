import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from '../../core/auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl(null, [Validators.required]),
    password: new UntypedFormControl(null, [Validators.required]),
  });

  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    this.loginForm;
  }

  /**
   * by Login submit send a post login request to backend usinng th login form controller data username and password;
   */
  onSubmit(): void {
    const username = this.loginForm?.value.username;
    const password = this.loginForm?.value.password;
    this.authService.login(username, password);
  }
}
