import { WrapFormService } from './wrap-form.service';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-wrap-form',
  templateUrl: './wrap-form.component.html',
  styleUrls: ['./wrap-form.component.scss'],
})
export class WrapFormComponent {
  @Input() title: string = '';
  @Input() customLabel: string = '';
  @Input() formIsDirty: boolean = false;
  @Input() formIsValid: boolean = true;
  @Input() showDirtyIndicator = true;
  @Input() showHeaderActionBar = true;
  @Input() showTopSeperator = true;
  @Input() cancelButton: boolean = true;
  @Input() resetButton: boolean = false;
  @Input() customButton: boolean = false;
  @Input() cancelRouterLink: string = '../';
  @Input() customSubmitLabel: string = 'GENERAL.BUTTONS.SUBMIT';

  constructor(private wrapFormService: WrapFormService) {}

  /**
   * here can i use or call a custom function from my component using here button click where i call wrapFrom
   */
  customFunction() {
    this.wrapFormService.callMyMethod();
  }
}
