import { LogLevels } from './log-levels.enum';

export class LogEntry {
  entryDate: Date = new Date();
  message: string = '';
  level: LogLevels = LogLevels.Debug;
  extraInfo: any[] = [];
  logWithDate: boolean = true;
  source: string = '';
  buildLogString(): string {
    let ret: string = '';
    if (this.logWithDate) {
      ret = this.getCurrentDate();
    }
    ret += 'Type: ' + LogLevels[this.level];
    ret += ' - Message: ' + this.message;
    if (this.extraInfo.length) {
      ret += ' - Extra Info: ' + this.formatParams(this.extraInfo);
    }

    return ret;
  }

  /**
   * check at first if there at least one object in the array,
   * then Build comma-delimited string;
   * @param params
   * @returns
   */
  private formatParams(params: any[]): string {
    let ret: string = params.join(',');

    // Is there at least one object in the array?
    if (params.some((p) => typeof p === 'object')) {
      ret = '';

      // Build comma-delimited string
      for (const item of params) {
        ret += JSON.stringify(item) + ',';
      }
    }
    return ret;
  }

  getCurrentDate(): string {
    return new Date() + ' - ';
  }
}
