import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  languageCal: boolean = false;
  scroolTo: string = 'content';

  @ViewChild('help') private myScrollContainer: ElementRef | null = null;

  constructor(
    public translateService: TranslateService,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.scroolTo = this.config.data.scroolTo;
    if (this.scroolTo !== undefined) {
      setTimeout(() => {
        this.scroll(this.scroolTo);
      }, 10);
    }
  }

  public scroll(scrollId: string) {
    document.getElementById(scrollId)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
}
