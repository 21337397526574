<form (ngSubmit)="onSubmit()" [formGroup]="form">
  <app-wrap-form
    [formIsDirty]="form.dirty"
    [formIsValid]="form.valid"
    [customButton]="true"
    customLabel="{{ 'GENERAL.BUTTONS.CANCEL' | translate }}"
    [cancelButton]="false"
  >
    <div content style="width: 100%">
      <div class="p-fluid p-formgrid grid">
        <div class="p-field col-3 md-3 font_normal">
          {{ "CHANGE_PASSWORD.OLDPW" | translate }}
        </div>
        <div class="field col-9 md-6">
          <input
            type="password"
            id="oldPassword"
            pInputText
            formControlName="oldPassword"
          />
        </div>
      </div>
      <div class="fluid formgrid grid">
        <div class="field col-3 font_normal"></div>
        <div class="field col-9 md-6">
          <small
            class="p-error"
            *ngIf="
              form.get('oldPassword')?.hasError('required') &&
              form.get('oldPassword')?.invalid &&
              (form.get('oldPassword')?.dirty ||
                form.get('oldPassword')?.touched)
            "
          >
            {{ "CHANGE_PASSWORD.REQUIRED" | translate }}
          </small>
        </div>
      </div>

      <div class="p-fluid formgrid grid">
        <div class="field col-3 md-3 font_normal">
          {{ "CHANGE_PASSWORD.NEWPW" | translate }}
        </div>
        <div class="field col-9 md-6">
          <input
            type="password"
            pPassword
            pInputText
            id="newPassword"
            formControlName="newPassword"
          />
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-3 font_normal"></div>
        <div class="field col-9 md-6">
          <small
            class="p-error m-1"
            *ngIf="
              form.get('newPassword')?.hasError('required') &&
              form.get('newPassword')?.invalid &&
              (form.get('newPassword')?.dirty ||
                form.get('newPassword')?.touched)
            "
          >
            {{ "CHANGE_PASSWORD.REQUIRED" | translate }}
          </small>

          <small
            class="p-error m-1"
            *ngIf="
              form.get('newPassword')?.hasError('pattern') &&
              form.get('newPassword')?.invalid &&
              (form.get('newPassword')?.dirty ||
                form.get('newPassword')?.touched)
            "
          >
            {{ "CHANGE_PASSWORD.CAPITAL_SMALL_AND_8_STANDART" | translate }}
          </small>
        </div>
      </div>

      <div class="p-fluid formgrid grid">
        <div class="field col-3 md-3 font_normal">
          {{ "CHANGE_PASSWORD.CONFIRMNEWPW" | translate }}
        </div>
        <div class="field col-9 md-6">
          <input
            type="password"
            pPassword
            pInputText
            id="newPasswordConfirmation"
            formControlName="newPasswordConfirmation"
            legindaCompare="newPassword"
          />
        </div>
      </div>
      <div class="p-fluid formgrid grid">
        <div class="field col-3 font_normal"></div>
        <div class="field col-9 md-6">
          <small
            class="p-error m-1"
            *ngIf="
              form.get('newPasswordConfirmation')?.hasError('required') &&
              form.get('newPasswordConfirmation')?.invalid &&
              (form.get('newPasswordConfirmation')?.dirty ||
                form.get('newPasswordConfirmation')?.touched)
            "
          >
            {{ "CHANGE_PASSWORD.REQUIRED" | translate }}
          </small>

          <small
            class="p-error m-1"
            *ngIf="
              form.get('newPasswordConfirmation')?.hasError('pattern') &&
              form.get('newPasswordConfirmation')?.invalid &&
              (form.get('newPasswordConfirmation')?.dirty ||
                form.get('newPasswordConfirmation')?.touched)
            "
          >
            {{ "CHANGE_PASSWORD.CAPITAL_SMALL_AND_8_STANDART" | translate }}
          </small>

          <small
            class="p-error"
            *ngIf="
              form.get('newPasswordConfirmation')?.invalid &&
              (form.get('newPasswordConfirmation')?.dirty ||
                form.get('newPasswordConfirmation')?.touched)
            "
          >
            <div
              *ngIf="form.get('newPasswordConfirmation')?.hasError('compare')"
            >
              {{ "CHANGE_PASSWORD.PASSWORD_MATCH_CONFIRMATION" | translate }}
            </div>
          </small>
        </div>
      </div>
    </div>
  </app-wrap-form>
</form>
