import { Injectable } from '@angular/core';
import { LogLevels } from './log-levels.enum';
import { LogEntry } from './log-entry';
import { LogPublisherService } from './log-publisher-service';
import { LogPublisher } from './log-publisher';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  publishers: LogPublisher[] = [];
  level: LogLevels = environment.log.logLevel;
  logWithDate: boolean = environment.log.logWithDate;

  constructor(private publisherService: LogPublisherService) {
    this.publishers = this.publisherService.publishers;
  }

  debug(msg: string, source: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevels.Debug, source, optionalParams);
  }

  info(msg: string, source: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevels.Notice, source, optionalParams);
  }

  warning(msg: string, source: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevels.Warning, source, optionalParams);
  }

  error(msg: string, source: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevels.Error, source, optionalParams);
  }

  fatal(msg: string, source: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevels.Trace, source, optionalParams);
  }

  // log(msg: string, ...optionalParams: any[]) {
  //   this.writeToLog(msg, LogLevels.All, optionalParams);
  // }

  // private writeToLog(logMessage: string, logSource: string, logLevels: number) {
  //   if (this.shouldLog(logLevels)) {
  //     for (let logger of this.publishers) {
  //       logger.log(logMessage, logSource, logLevels).subscribe(response => console.log("logResonse", response));
  //     }
  //   }
  // }

  /**
   * write the allow kind of.
   * @param msg
   * @param level
   * @param source
   * @param params
   */
  private writeToLog(
    msg: string,
    level: LogLevels,
    source: string,
    params: any[]
  ) {
    if (this.shouldLog(level)) {
      const logEntry: LogEntry = new LogEntry();
      logEntry.message = msg;
      logEntry.level = level;
      logEntry.source = source;
      logEntry.extraInfo = params;
      logEntry.logWithDate = this.logWithDate;
      for (const logger of this.publishers) {
        logger.log(logEntry).subscribe();
      }
    }
  }

  /**
   * Validated which log allow showing of the admin configuration in the environment file and allow it.
   * @param level
   * @returns
   */
  private shouldLog(level: LogLevels): boolean {
    let ret: boolean = false;
    if (
      (level >= this.level && level !== LogLevels.Off) ||
      this.level === LogLevels.Error
    ) {
      ret = true;
    }
    return ret;
  }
}
