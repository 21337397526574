<div class="grid justify-content-center mt-3">
  <div class="col-6 sm:col-12 md:col-6 lg:col-6 xl:col-4">
    <p-card styleClass="my-4 ">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="p-fluid">
          <div class="field">
            <label for="username">Username</label>
            <input id="username" pInputText formControlName="username" />
          </div>

          <div class="field">
            <label for="password">Password</label>
            <input
              type="password"
              id="password"
              pPassword
              formControlName="password"
            />
          </div>

          <button
            pButton
            type="submit"
            [disabled]="!loginForm.valid"
            label="Submit"
          ></button>
        </div>
      </form>
    </p-card>
  </div>
</div>
