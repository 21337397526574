import { DepartmentResponseInterface } from '../model/departments/department-response-interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserResponseInterface } from 'src/app/core/model/user-response-interface';
import { environment } from 'src/environments/environment';
import { User } from '../model/user.model';
import { BillingAdressResponseInterface } from '../../modules/organization-managmnet/models/billing-adress/billing-adress-response-interface';
import { CompanyResponseInterface } from '../model/company-response-interface';
import { Company } from '../model/company.model';
import { GenericResponseInterface } from '../model/generic-response.interface';
import { BillingAdress } from '../../modules/organization-managmnet/models/billing-adress/billing-adress';
import { DiscountPointResponseInterface } from '../../modules/organization-managmnet/models/discountPoint/discount-point-response-interface';

@Injectable({
  providedIn: 'root',
})
export class OrganizationManagementService {
  resourceUrlSegment = 'organizationManagement';

  constructor(private http: HttpClient) {}

  // ***** Get *****
  // getBillingAddresses(): Observable<BillingAdressResponseInterface> {
  //   return this.http.get<BillingAdressResponseInterface>('assets/Mockserver/billingAdresses.json');
  // }

  /**
   * get ll billing addresses by company Id;
   * @param companyId number;
   * @returns BillingAdressResponseInterface;
   */
  getBillingAddresses(
    companyId: number
  ): Observable<BillingAdressResponseInterface> {
    return this.http.get<BillingAdressResponseInterface>(
      `${environment.baseUrl}billingaddresses/company/` + companyId
    );
  }

  /**
   * get billingAddress By billingAdressId ;
   * @param id billingAdressId number;
   * @returns BillingAdressResponseInterface;
   */
  getbillingAdressById(id: number): Observable<BillingAdressResponseInterface> {
    return this.http.get<BillingAdressResponseInterface>(
      `${environment.baseUrl}billingaddress/` + id
    );
    // return this.http.get<BillingAdressResponseInterface>('assets/Mockserver/billingAdresses.json');
  }

  /**
   * get user by user id ;
   * @param id userId number;
   * @returns UserResponseInterface
   */
  getUserById(id: number): Observable<UserResponseInterface> {
    return this.http.get<UserResponseInterface>(
      `${environment.baseUrl}users/` + id
    );
  }

  /**
   * get company by Company Id ;
   * @param companyId number ;
   * @returns CompanyResponseInterface;
   */
  getCompany(companyId: number): Observable<CompanyResponseInterface> {
    return this.http.get<CompanyResponseInterface>(
      `${environment.baseUrl}companies/` + companyId
    );
  }

  /**
   * get Members By CompanyId ;
   * @param comapnyId number ;
   * @returns GenericResponseInterface;
   */
  getMembersByCompanyId(comapnyId: number) {
    return this.http.get<GenericResponseInterface>(
      `${environment.baseUrl}users/company/` + comapnyId,
      {}
    );
  }

  /**
   *  get Ornagisations Array by CompanyId;
   * @param companyId number;
   * @returns GenericResponseInterface;
   */
  getDepartmentsByCompanyId(companyId: number) {
    return this.http.get<DepartmentResponseInterface>(
      `${environment.baseUrl}departments/` + companyId,
      {}
    );
  }

  // ***** PUT *****
  // updateUser(updatedUser: User) {
  //   return this.http.put<GenericResponseInterface>(`${environment.baseUrl}users/` + updatedUser.id, {
  //     'first_name': updatedUser.firstName,
  //     'last_name': updatedUser.lastName,
  //     'language': updatedUser.language,
  //     'phone': updatedUser.phone,
  //     'mobile': updatedUser.mobile,
  //     // 'payment_type_debit': updatedUser.paymentTypeDebit,
  //   });
  // }

  /**
   * update user Password;
   * @param oldPassword string;
   * @param newPassword string;
   * @param newPassordConfirm string;
   * @returns GenericResponseInterface;
   */
  updatePassword(
    oldPassword: string,
    newPassword: string,
    newPassordConfirm: string
  ) {
    return this.http.put<GenericResponseInterface>(
      `${environment.baseUrl}password`,
      {
        password: oldPassword,
        newpass: newPassword,
        newpassRepeated: newPassordConfirm,
      }
    );
  }

  /**
   * update BillingAddress object ;
   * @param updateBillingAdress BillingAdress;
   * @returns BillingAdressResponseInterface;
   */
  updateBillingAdress(updateBillingAdress: BillingAdress) {
    return this.http.put<BillingAdressResponseInterface>(
      `${environment.baseUrl}billingaddress/${updateBillingAdress.id}`,
      updateBillingAdress
    );
  }

  /**
   * update Company Object;
   * @param updatedCompany Company;
   * @returns GenericResponseInterface;
   */
  updateCompany(updatedCompany: Company) {
    return this.http.put<GenericResponseInterface>(
      `${environment.baseUrl}companies/${updatedCompany.id}`,
      updatedCompany
    );
  }

  /**
   * update company profile verion ;
   * @param updatedCompany
   * @returns GenericResponseInterface;
   */
  updateCompanyProfile(updatedCompany: Company) {
    return this.http.put<GenericResponseInterface>(
      `${environment.baseUrl}profile/updateCompany/${updatedCompany.id}`,
      {
        updatedCompany,
      }
    );
  }

  /**
   *
   * @param newBillingadressId number;
   * @returns  GenericResponseInterface;
   */
  changeBillingAdress(newBillingadressId: number) {
    return this.http.put<GenericResponseInterface>(
      `${environment.baseUrl}${this.resourceUrlSegment}/${newBillingadressId}`,
      newBillingadressId
    );
  }

  /**
   * update user ;
   * @param updatesUser User;
   * @returns GenericResponseInterface;
   */
  updateUser(updatesUser: User) {
    return this.http.put<GenericResponseInterface>(
      `${environment.baseUrl}users/` + updatesUser.id,
      {
        active: updatesUser.active,
        businessUnitId: updatesUser.businessUnitId,
        changePot: updatesUser.changePot,
        companyId: updatesUser.companyId,
        createPot: updatesUser.createPot,
        deletedAt: updatesUser.deletedAt,
        department: updatesUser.department,
        email: updatesUser.email,
        firstName: updatesUser.firstName,
        forwardedWithCouponCase: updatesUser.forwardedWithCouponCase,
        globalActive: updatesUser.globalActive,
        groupId: updatesUser.groupId,
        id: updatesUser.id,
        isDeleted: updatesUser.isDeleted,
        language: updatesUser.language,
        lastName: updatesUser.lastName,
        loginPot: updatesUser.loginPot,
        loginPot2: updatesUser.loginPot2,
        mobile: updatesUser.mobile,
        paymentTypeDebit: updatesUser.paymentTypeDebit,
        phone: updatesUser.phone,
        sendStatusEmails: updatesUser.sendStatusEmails,
        userName: updatesUser.userName,
        userProperties: {
          CAN_ACCESS_COMPANY_CASES:
            updatesUser.userProperties.CAN_ACCESS_COMPANY_CASES,
          CAN_DOWNLOAD_BILLS: updatesUser.userProperties.CAN_DOWNLOAD_BILLS,
          CAN_EDIT_COMPANY_DATA:
            updatesUser.userProperties.CAN_EDIT_COMPANY_DATA,
          CAN_MANAGE_COMPANY_USERS:
            updatesUser.userProperties.CAN_MANAGE_COMPANY_USERS,
        },
      }
    );
  }

  // ***** Post *****

  /**
   * post request to create new User ;
   * @param user User;
   * @param comapnyID number;
   * @returns GenericResponseInterface;
   */
  createNewUser(user: User, comapnyID: number) {
    return this.http.post<GenericResponseInterface>(
      `${environment.baseUrl}users/${comapnyID}`,
      user
    );
  }

  /**
   * post request to create newBillingAddress;
   * @param billingaddress BillingAdress;
   * @returns BillingAdressResponseInterface;
   */
  addNewBillingaddress(billingaddress: BillingAdress) {
    return this.http.post<BillingAdressResponseInterface>(
      `${environment.baseUrl}billingaddress`,
      billingaddress
    );
  }

  // ***** Delete *****

  /**
   * delete BillingAddress BY billingAddressId;
   * @param billingAdressId number;
   * @returns GenericResponseInterface;
   */
  deleteBillingAddress(billingAdressId: number) {
    return this.http.delete<GenericResponseInterface>(
      `${environment.baseUrl}billingaddress/${billingAdressId}`
    );
  }

  /**
   * deleteCompany Member by UserId number;
   * @param userId number;
   * @returns GenericResponseInterface;
   */
  deleteCompanyMember(userId: number) {
    return this.http.delete<GenericResponseInterface>(
      `${environment.baseUrl}users/${userId}`
    );
  }

  getDiscountPoints(companyId: number) {
    return this.http.get<DiscountPointResponseInterface>(
      `${environment.baseUrl}points/` + companyId
    );
  }

  getFinalPriceWithDiscount(discount: number) {
    return this.http.get<GenericResponseInterface>(
      `${environment.baseUrl}finlaPriceWithDiscount/${discount}`
    );
  }
}
