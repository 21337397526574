import { Category } from '../models/category';
import { Item } from '../models/item';

export class CustomerMenuSupplier {
  public getCustomerMenu(showBills: boolean = true): Category[] {
    const categories: Category[] = [];
    const mainCateGory = new Category('');
    const dashboard = {
      name: 'GLOBAL_MENU.CUSTOMER.HOME',
      icon: 'pi-home',
      link: '/dashboard',
      deactivated: false,
      description: '',
    } as Item;
    const getOffer = {
      name: 'GLOBAL_MENU.CUSTOMER.GETOFFER',
      icon: 'pi-tag',
      link: '/cases/getOffer',
      deactivated: false,
      description: '',
    } as Item;
    const offers = {
      name: 'GLOBAL_MENU.CUSTOMER.OFFERS',
      icon: 'pi-shopping-cart',
      link: '/cases/offers',
      deactivated: false,
      description: '',
    } as Item;
    const cases = {
      name: 'GLOBAL_MENU.CUSTOMER.ORDERS',
      icon: 'pi-briefcase',
      link: '/cases/orders',
      deactivated: false,
      description: '',
    } as Item;
    const bills = {
      name: 'GLOBAL_MENU.CUSTOMER.BILLS',
      icon: 'pi-wallet',
      link: '/bills',
      deactivated: false,
      description: '',
    } as Item;
    const feedback = {
      name: 'GLOBAL_MENU.CUSTOMER.FEEDBACK',
      icon: 'pi-envelope',
      link: '/feedback',
      deactivated: false,
      description: '',
    } as Item;

    mainCateGory.items.push(dashboard);
    mainCateGory.items.push(getOffer);
    mainCateGory.items.push(offers);
    mainCateGory.items.push(cases);
    if (showBills) mainCateGory.items.push(bills);
    mainCateGory.items.push(feedback);
    categories.push(mainCateGory);
    return categories;
  }
}
