import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericResponseInterface } from '../model/generic-response.interface';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private http: HttpClient, private urlService: UrlService) {}

  // /**
  //  *
  //  * @param languageId
  //  * @returns language Objecet
  //  */
  // public getLanguageById(languageId: number) {
  //   return this.http.get<GenericResponseInterface>(this.urlService.getBaseUrl() + 'languages/' + languageId, this.urlService.getDefaultHttpOptions());
  // }

  /**
   *
   * @returns language[]
   */
  public getLanguages() {
    return this.http.get<GenericResponseInterface>(
      this.urlService.getBaseUrl() + 'languages',
      this.urlService.getDefaultHttpOptions()
    );
  }
}
