<div class="flex flex-column">
  <div *ngIf="showTopSeperator" class="">
    <hr />
  </div>
  <div *ngIf="showDirtyIndicator" class="flex justify-content-end text-right">
    <span class="font_normal_bold mr-3"><i *ngIf="formIsDirty">*</i></span>
  </div>
  <div class="flex flex-column">
    <div class="">
      <ng-content select="[content]"></ng-content>
    </div>
    <!-- hier kommt das Page Content -->
    <div>
      <hr />
    </div>

    <div class="flex justify-content-between" *ngIf="showHeaderActionBar">
      <div>
        <button
          class="ml-1 button shadow-4"
          pButton
          type="reset"
          *ngIf="cancelButton"
          label="{{ 'GENERAL.BUTTONS.CANCEL' | translate }}"
          icon="pi pi-times"
          routerLink="{{ cancelRouterLink }}"
        ></button>
        <button
          class="ml-1 button shadow-4"
          pButton
          type="reset"
          *ngIf="resetButton"
          label="{{ 'GENERAL.BUTTONS.RESET' | translate }}"
          icon="pi pi-times"
        ></button>
        <button
          class="ml-1 button shadow-4"
          pButton
          type="reset"
          *ngIf="customButton"
          label=" {{ customLabel }}"
          icon="pi pi-times"
          (click)="customFunction()"
        ></button>
      </div>
      <button
        pButton
        type="submit "
        [disabled]="!formIsValid"
        label=" {{ customSubmitLabel | translate }}"
        icon="pi pi-check"
        class="mr-1 button shadow-4"
      ></button>
    </div>
  </div>
</div>
