export class ApiException implements Error {
  readonly name = 'ApiException';
  readonly message: string;
  displayError: boolean = true;
  additionalInfo: string = '';
  title: string = '';
  stacktrace: string = '';

  constructor(message: string) {
    this.message = message;
  }
}
