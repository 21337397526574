import { Injectable } from '@angular/core';
import { Language } from '../model/language.model';
import { LanguageService } from './language.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageTranslateService {
  private languages: Language[] = [];

  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService
  ) {
    this.init();
  }

  /**
   *  return the languages list..
   * @returns Language[]
   */
  public getLanguagesList(): Language[] {
    return this.languages;
  }

  /**
   * if the language list not empty then return language name by language
   * id by calling the mehtode translateByLanguageId else call the init methode.
   * @param lang
   * @returns
   */
  public translate(lang: any): string {
    if (this.languages.length > 0) {
      return this.translateByLanguageId(lang);
    } else {
      this.init();
    }
    return '';
  }

  /**
   *
   * @param langId number
   * @returns language name as a string
   */
  public translateByLanguageId(langId: number): string {
    const found = this.languages.find((e) => e.languageId === langId);
    if (found) {
      if (this.translateService.currentLang === 'de') {
        return found.languageNameDe;
      } else {
        return found.languageNameEn;
      }
    }
    return 'Translation-error';
  }

  /**
   *
   * @param code string
   * @returns the language name as a string
   */
  translateByCode(code: string): string {
    const found = this.languages.find(
      (e) => e.cultureInfo != null && e.cultureInfo.toLowerCase().includes(code)
    );
    if (found) {
      if (this.translateService.currentLang === 'de') {
        return found.languageNameDe;
      } else {
        return found.languageNameEn;
      }
    }
    return 'Translation-error';
  }

  /**
   * check if the language[] array in lockal storage is not empty then give it back
   * else send the get request to backend to get the language array and store it in lockal storage
   */
  init() {
    if (this.languages.length === 0) {
      const languages = localStorage.getItem('languages');
      if (languages) {
        this.languages = Object.assign(JSON.parse(languages));
      }
      this.languageService.getLanguages().subscribe((e) => {
        this.languages = e.data;
        this.updateLocallyStoredLanguages();
      });
    }
  }

  /**
   * set the language array in lockal storage.
   */
  updateLocallyStoredLanguages() {
    localStorage.setItem('languages', JSON.stringify(this.languages));
  }
}
