import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {
  constructor(public authService: AuthService, private router: Router) {}

  canLoad(
    _route: Route,
    _segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const permmision = 'view_Home';
    //const permmision = route.data?.permmision

    if (
      this.authService.user == null ||
      this.authService.user.can(permmision) === false
    ) {
      this.router.navigate(['login']);
      return false;
    }

    return true;
  }
}
