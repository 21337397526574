import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UrlService {
  /**
   *
   * @returns the baseUrl from the enviroment.
   */
  getBaseUrl() {
    return environment.baseUrl;
  }

  /**
   *
   * @returns
   */
  getDefaultHttpOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
  }
}
