import { MessageService } from 'primeng/api';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class InfoMessageService {
  constructor(
    private message: MessageService,
    private translateService: TranslateService
  ) {}

  showMessage(
    severity: string,
    summary: string,
    details: string,
    duration: number = 3000
  ): void {
    this.message.add({ severity: severity, summary, detail: details });
    setTimeout(() => {
      this.message.clear;
    }, duration);
  }

  showSuccessMessage(details: string): void {
    this.showMessage(
      'success',
      this.translateService.instant('GENERAL.SUCCESS'),
      details
    );
  }

  showInfoMessage(details: string): void {
    this.showMessage('info', 'Info', details);
  }

  showWarrningMessage(details: string): void {
    this.showMessage('warn', 'Warning', details);
  }

  showErrorMessage(details: string): void {
    this.showMessage(
      'error',
      this.translateService.instant('GENERAL.ERROR'),
      details
    );
  }
}
