import { LogLevels } from '../app/core/logging/log-levels.enum';

export const environment = {
  production: true,
  notificationsInterval: 900000,
  jwtTimeoutHeadsUpInSeconds: 300,
  uploadMaxFilesize: 1024000000,
  log: {
    logLevel: LogLevels.Error,
    logWithDate: true,
    logToConsole: true,
    logToWebAPI: true,
    logToLocalStorage: false,
  },
  baseUrl: 'https://holger.dev-leginda.de/api/',
};
