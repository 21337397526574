import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CaseResponseInterface } from '../../model/case/case-response-interface';
import { EventResponseInterface } from '../../model/event-model/event-response-interface';
import { EventsNumberResponseInterface } from '../../model/event-model/newEventsNumber-response-interface';
import { User } from '../../model/user.model';
import { UserModel } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor(private http: HttpClient) {}

  /**
   * get the stored User from localStorage
   * @returns UserModel
   */
  getuserInfo(): UserModel {
    const user: UserModel = new UserModel();
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const currentUser = Object.assign(new User(), JSON.parse(storedUser));
      user.firstName = currentUser.firstName;
      user.lastName = currentUser.lastName;
      user.company = currentUser.company;
      user.email = currentUser.email;
      user.id = currentUser.id;
    }
    return user;
  }

  // *****GET *****/

  /**
   * send a request to backend and get back the new Notification number
   * @returns Observable<EventsNumberResponseInterface>
   */
  getNewNotificationNumber(): Observable<EventsNumberResponseInterface> {
    return this.http.get<EventsNumberResponseInterface>(
      `${environment.baseUrl}events/countNewEvents`
    );
  }

  /**
   *get all new Notification Objects
   * @returns Observable<EventResponseInterface>
   */
  getAllNewNotification(): Observable<EventResponseInterface> {
    return this.http.get<EventResponseInterface>(
      `${environment.baseUrl}events/newEvents`
    );
  }

  // getNotificationById(id: number): Observable<GenericResponseInterface> {
  //   return this.http.get<GenericResponseInterface>(`${environment.baseUrl}notificationById/${id}`);
  // }

  /**
   * send a request to backedn to get a case By Id
   * @param id
   * @returns Observable<CaseResponseInterface>
   */
  getCaseById(id: number): Observable<CaseResponseInterface> {
    return this.http.get<CaseResponseInterface>(
      `${environment.baseUrl}cases/` + id
    );
  }

  // *****PUT *****/

  /**
   *send a request to backEnd to change the isNew status to false so it's seen when the user see it.
   * @param eventId
   * @param isNew
   * @returns
   */
  updateNotificationToSeen(
    eventId: number,
    isNew: boolean
  ): Observable<EventResponseInterface> {
    return this.http.put<EventResponseInterface>(
      `${environment.baseUrl}events/${eventId}`,
      {
        isNew: isNew,
      }
    );
  }
}
