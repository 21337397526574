import { LogPublisher } from '../log-publisher';
import { Observable, of } from 'rxjs';
import { LogEntry } from '../log-entry';

export class LogLocalStoragePublisher extends LogPublisher {
  constructor() {
    super();
    this.location = 'logging';
  }

  /**
   * remove the log item from localStorge
   * @returns Observable<boolean>
   */
  clear(): Observable<boolean> {
    localStorage.removeItem(this.location);
    return of(true);
  }

  /**
   *prase the item to JSON file and store it in localStorage
   * @param record
   * @returns Observable<boolean>
   */
  log(record: LogEntry): Observable<boolean> {
    let ret: boolean = false;
    let values: LogEntry[] = [];
    try {
      // Get previous values from local storage
      values = JSON.parse(<string>localStorage.getItem(this.location)) || [];
      // Add new log entry to array
      values.push(record);
      // Store array into local storage
      localStorage.setItem(this.location, JSON.stringify(values));
      // Set return value
      ret = true;
    } catch (ex) {
      // Display error in console
    }

    return of(ret);
  }
}
