import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import {
  DialogService,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RatingModule } from 'primeng/rating';
import { SkeletonModule } from 'primeng/skeleton';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { PayOneComponent } from './core/components/pay-one/pay-one.component';
import { WaitingSpinnerComponent } from './core/components/waitingSpinner/waitingSpinner.component';
import { CompareValidatorDirective } from './core/directive/compare-validator.directive';
import { AttachmentTypePipe } from './core/pipes/attachment-type.pipe';
import { CaseTypePipe } from './core/pipes/case-type.pipe';
import { ContryCodeFlagPipe } from './core/pipes/contry-flag-code.pipe';
import { CountryNamePipe } from './core/pipes/country-name.pipe';
import { CurrencySymbolPipe } from './core/pipes/currency-symbol.pipe';
import { EventTypeBgcolorPipePipe } from './core/pipes/event-type-bgcolor.pipe';
import { EventTypePipe } from './core/pipes/event-type.pipe';
import { FileSizePipe } from './core/pipes/file-size.pipe';
import { LanguageNamePipe } from './core/pipes/language-name.pipe';
import { OrderDeliveryTypePipe } from './core/pipes/order-delivery-type.pipe';
import { OrderStatusPipe } from './core/pipes/order-status.pipe';
import { PaymentTypePipePipe } from './core/pipes/payment-type.pipe';
import { SpecialistFieldPipe } from './core/pipes/specialist-field.pipe';
import { WrapFormComponent } from './core/wrap-form/wrap-form.component';
import { HelpComponent } from './core/components/help/help.component';
import { FieldsetModule } from 'primeng/fieldset';
import { DividerModule } from 'primeng/divider';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    RouterModule,
    ConfirmDialogModule,
    TranslateModule,
    SkeletonModule,
    TableModule,
    CardModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    FieldsetModule,
    DividerModule,
  ],
  declarations: [
    WrapFormComponent,
    ContryCodeFlagPipe,
    AttachmentTypePipe,
    CaseTypePipe,
    LanguageNamePipe,
    OrderDeliveryTypePipe,
    PaymentTypePipePipe,
    FileSizePipe,
    CurrencySymbolPipe,
    EventTypePipe,
    EventTypeBgcolorPipePipe,
    OrderStatusPipe,
    CountryNamePipe,
    SpecialistFieldPipe,
    WaitingSpinnerComponent,
    HelpComponent,
    PayOneComponent,
    CompareValidatorDirective,
  ],
  providers: [
    ConfirmationService,
    TranslateService,
    DialogService,
    DynamicDialogRef,
  ],
  exports: [
    //********* Other Module *********//
    WrapFormComponent,
    WaitingSpinnerComponent,
    PayOneComponent,
    TranslateModule,
    HelpComponent,
    //********* Pipe *****************//
    ContryCodeFlagPipe,
    CaseTypePipe,
    AttachmentTypePipe,
    LanguageNamePipe,
    EventTypePipe,
    OrderDeliveryTypePipe,
    FileSizePipe,
    CountryNamePipe,
    OrderStatusPipe,
    SpecialistFieldPipe,
    CurrencySymbolPipe,
    PaymentTypePipePipe,
    EventTypeBgcolorPipePipe,
    //********* Directive *****************//
    CompareValidatorDirective,
    //********* Primng Module *********//
    MegaMenuModule,
    CardModule,
    ButtonModule,
    InputTextModule,
    MenuModule,
    MenubarModule,
    MessagesModule,
    MessageModule,
    TreeModule,
    ListboxModule,
    ToastModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    RatingModule,
    TableModule,
    SliderModule,
    CalendarModule,
    DropdownModule,
    ProgressBarModule,
    TabViewModule,
    PanelModule,
    PasswordModule,
    ConfirmDialogModule,
    ToolbarModule,
    AvatarModule,
    FileUploadModule,
    TieredMenuModule,
    DynamicDialogModule,
    TooltipModule,
    CheckboxModule,
    InputSwitchModule,
    SkeletonModule,
    CardModule,
    BlockUIModule,
    FieldsetModule,
    BadgeModule,
    OverlayPanelModule,
    VirtualScrollerModule,
  ],
})
export class UiModule {}
