import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, isDevMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ApiException } from '../interceptors/api-exception';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(
    private notificationService: NotificationService,
    private translate: TranslateService
  ) {}
  handleError(error: any): void {
    if (error.rejection instanceof ApiException) {
      const ex = error.rejection as ApiException;
      if (
        ex.displayError &&
        typeof ex.stacktrace === 'string' &&
        ex.stacktrace
          .toLocaleLowerCase()
          .startsWith(
            'microsoft.entityframeworkcore.dbupdateconcurrencyexception'
          )
      ) {
        this.notificationService.showMessage(
          this.messageFormatter('CONCURRENCY_EXCEPTION_DETAIL'),
          this.translate.instant('CONCURRENCY_EXCEPTION'),
          'error',
          true,
          3000,
          ex.additionalInfo
        );
      } else if (ex.displayError)
        this.notificationService.showMessage(
          this.messageFormatter(
            ex.message !== '' && ex.message != null ? ex.message : 'UNKNOWN'
          ),
          this.translate.instant(
            ex.title !== '' && ex.title != null ? ex.title : 'UNKNOWN'
          ),
          'error',
          true,
          3000,
          ex.additionalInfo
        );
      // eslint-disable-next-line no-console
      if (isDevMode()) console.error(error);
    } else if (error instanceof ApiException) {
      const ex = error as ApiException;
      if (ex.displayError)
        this.notificationService.showMessage(
          this.messageFormatter(
            ex.message !== '' && ex.message != null ? ex.message : 'UNKNOWN'
          ),
          this.translate.instant(
            ex.title !== '' && ex.title != null ? ex.title : 'UNKNOWN'
          ),
          'error',
          true,
          3000,
          ex.additionalInfo
        );
      // eslint-disable-next-line no-console
      if (isDevMode()) console.error(error);
    } else if (!(error.rejection instanceof HttpErrorResponse)) {
      this.notificationService.showMessage(
        this.messageFormatter(
          `${error.message ?? error} - ${error.stack ?? ''}`
        ),
        this.translate.instant('UNHANDLED_EXCEPTION'),
        'error',
        true
      );
      // eslint-disable-next-line no-console
      if (isDevMode()) console.error(error);
    } else if (error.error instanceof ErrorEvent) {
      // client-side error
      const errorMessage = `Error: ${error.error.message}`;
      this.notificationService.showMessage(
        errorMessage,
        'CLIENTSIDE_ERROR_HEADLINE',
        'error',
        true
      );
      // eslint-disable-next-line no-console
      if (isDevMode()) console.error(error);
    } else if (error instanceof TypeError) {
      // client-side error
      const errorMessage = `Error: ${error.message}`;
      this.notificationService.showMessage(
        errorMessage,
        'CLIENTSIDE_ERROR_HEADLINE',
        'error',
        true
      );
      // eslint-disable-next-line no-console
      if (isDevMode()) console.error(error);
    }
  }

  messageFormatter(message: string): string {
    return message.replace(/&quot;/gm, '"');
  }
}
