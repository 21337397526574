import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private messageService: MessageService,
    private translate: TranslateService
  ) {}

  showMessage(
    message: string | string[],
    title: string = '',
    severity: 'success' | 'info' | 'warn' | 'error' = 'info',
    permanent: boolean = true,
    life: number = 3000,
    additionalInfo: string | null = null
  ): void {
    let resultMessage = '';
    if (
      additionalInfo != null &&
      additionalInfo !== '' &&
      additionalInfo !== 'None'
    ) {
      resultMessage +=
        '\nInfo: ' + this.translate.instant(additionalInfo) + '\n';
    }
    if (Array.isArray(message)) {
      resultMessage += message.map((m) => this.translate.instant(m)).join('\n');
    } else {
      resultMessage += this.translate.instant(message);
    }
    if (title !== '') title = this.translate.instant(title);
    if (permanent) {
      this.messageService.add({
        key: 'errorDlg',
        sticky: true,
        severity: severity,
        summary: title,
        detail: resultMessage,
      });
    } else
      this.messageService.add({
        severity: severity,
        summary: title,
        detail: resultMessage,
        life,
      });
  }
}
