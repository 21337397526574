import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/core/auth/auth.service';
import { environment } from 'src/environments/environment';
import { GenericResponseInterface } from '../../model/generic-response.interface';
import { WrapFormService } from '../../wrap-form/wrap-form.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private router: Router,
    private ref: DynamicDialogRef,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
    private wrapFormService: WrapFormService,
    private http: HttpClient,
    private authService: AuthService,
    private translateService: TranslateService
  ) {
    this.form = this.formBuilder.group({
      oldPassword: new UntypedFormControl('', Validators.required),
      newPassword: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$'),
      ]),
      newPasswordConfirmation: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,32}$'),
      ]),
    });
  }

  ngOnInit(): void {
    this.wrapFormService.invokeMyMethod.subscribe(() => {
      this.logout();
    });
  }

  /**
   * when th euser submit the password changes then send this changes to backend and
   * if success navigate to home router and close the popup dialog ;
   */
  onSubmit(): void {
    this.form.markAsDirty();
    if (this.form.value.newPassword === this.form.value.newPassordConfirm) {
      // this.http.put<GenericResponseInterface>(`${environment.baseUrl}password`, {
      //   'newpass': this.form.value.newPassword,
      //   'newpassRepeated': this.form.value.newPassordConfirm
      // })
      this.http
        .put<GenericResponseInterface>(`${environment.baseUrl}password`, {
          password: this.form.value.oldPassword,
          newpass: this.form.value.newPassword,
          newpassRepeated: this.form.value.newPassordConfirm,
        })
        .subscribe(
          () => {
            this.messageService.add({
              severity: 'success',
              summary: this.translateService.instant(
                'GENERAL.RESPONSES.UPDATED_HEADER'
              ),
              detail: this.translateService.instant(
                'GENERAL.RESPONSES.UPDATED'
              ),
            });
            this.form.markAsPristine();
            this.router.navigate(['home']);
            this.closeWin();
          },
          () => {
            this.messageService.add({
              severity: 'error',
              summary: this.translateService.instant(
                'GENERAL.RESPONSES.UPDATE_FAIL_HEADER'
              ),
              detail: this.translateService.instant(
                'GENERAL.RESPONSES.UPDATE_FAIL'
              ),
            });
          }
        );
    }
  }

  /**
   * close ths dialog popup;
   */
  closeWin() {
    this.ref.close(this.form);
  }

  /**
   * logout the user ;
   */
  logout() {
    this.authService.logout();
  }
}
